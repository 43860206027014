
import './App.css';
import { Navbar } from './Components/navbar/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { All_servers } from './Components/Home/Home';
import { PageCategory } from './Components/Pages/PageCategory';
import { Server } from './Components/Pages/Server';
import LoginSignup from './Components/Pages/LoginSignup';
import MyAccount from './Components/Pages/MyAccount';
import {BuyCoins} from './Components/Pages/BuyCoins';
import AddMyServer from './Components/Pages/AddMyServer';
import BuyBanner from './Components/Pages/BuyBanner';
import { Elements } from '@stripe/react-stripe-js';
import Search from './Components/Pages/Search';
import { useContext, useEffect, useState  } from 'react';
import { ServersContext } from './Components/ServersContext';
import Helmett from './Helmett';
import EditAccount from './Components/Pages/EditAccount';
import ResetPassword from './Components/Pages/reset-password';
import ForgotPassword from './Components/Pages/forgot-password';
import PrivateRoute from './PrivateRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TermsOfUse } from './Components/Terms&Cookies/TermsOfUse';
import { CookiesPolicy } from './Components/Terms&Cookies/CookiesPolicy';
import { DataPolicy } from './Components/Terms&Cookies/DataPolicy';
import { Footer } from './Components/Footer/Footer';
import { loadStripe } from '@stripe/stripe-js';
import Test from'./Components/Pages/test';

const stripePromise = loadStripe('pk_live_51JMqiOGlZZjBreoNFTyW4SglzyoLlFnsrAYyFCmclQwftFSe2FAZ2aJX2ZLWDNFoT9Tkz6DraDuKuqrNklBZHFMP00Tf3EG49A');

 
 // Mock authentication function
const isAuthenticated = () => {
  return localStorage.getItem('auth-token') !== null;
};

// Private Route component
 
  
function App() {
  const [searchQuery, setSearchQuery] = useState('');
const servContext = useContext(ServersContext);
const [searchResults, setSearchResults] = useState(null);

const servers = servContext.servers.servers;

useEffect(() => {
  if (!searchQuery.trim()) {
    setSearchResults(null); // Clear search results if the search query is empty
    return;
  }

  // Filter servers based on the search query
  const filteredResults = servers.filter(server => server.name.toLowerCase().includes(searchQuery.toLowerCase()));

  console.log('Filtered Results:', filteredResults); // Log filtered results

  setSearchResults(filteredResults); // Pass filtered results directly to onSearch
}, [searchQuery, servers]);


  return (
    <div>
      <BrowserRouter>
      <Helmett/>
        <Navbar />
        <main className='main-content'>
          
        <Routes>
          <Route path="/" element={<All_servers />} />
          <Route path="/Interlude" element={<PageCategory Chronicle="Interlude" />} />
  <Route path="/H5" element={<PageCategory Chronicle="H5" />} />
  <Route path="/Essence" element={<PageCategory Chronicle="Essence" />} />
  <Route path="/Hellbound" element={<PageCategory Chronicle="Hellbound" />} />
  <Route path="/Gracia" element={<PageCategory Chronicle="Gracia" />} />
  <Route path="/GraciaFinal" element={<PageCategory Chronicle="Gracia Final" />} />
  <Route path="/GraciaEpilogue" element={<PageCategory Chronicle="Gracia Epilogue" />} />
  <Route path="/Freya" element={<PageCategory Chronicle="Freya" />} />
 
  <Route path="/GoD" element={<PageCategory Chronicle="GoD" />} />
  <Route path="/GrandCrusade" element={<PageCategory Chronicle="Grand Crusade" />} />
  <Route path="/Helios" element={<PageCategory Chronicle="Helios" />} />
  <Route path="/Fafurion" element={<PageCategory Chronicle="Fafurion" />} />
  <Route path="/PreludeOfWar" element={<PageCategory Chronicle="Prelude of War" />} />
  <Route path="/Homunculus" element={<PageCategory Chronicle="Homunculus" />} />
  <Route path="/Classic" element={<PageCategory Chronicle="Classic" />} />
  <Route path="/Olympiad" element={<PageCategory Chronicle="Olympiad" />} />
  <Route path="/Essence" element={<PageCategory Chronicle="Essence" />} />
  <Route path="/Prelude" element={<PageCategory Chronicle="Prelude" />} />
  <Route path="/TheKamael" element={<PageCategory Chronicle="The Kamael" />} />
  <Route path="/GoddessOfDestruction" element={<PageCategory Chronicle="Goddess of Destruction" />} />
  <Route path="/Awakening" element={<PageCategory Chronicle="Awakening" />} />
  <Route path="/Harmony" element={<PageCategory Chronicle="Harmony" />} />
  <Route path="/Tauti" element={<PageCategory Chronicle="Tauti" />} />
  <Route path="/GloryDays" element={<PageCategory Chronicle="Glory Days" />} />
  <Route path="/Lindvior" element={<PageCategory Chronicle="Lindvior" />} />
  <Route path="/Valiance" element={<PageCategory Chronicle="Valiance" />} />
  <Route path="/Ertheia" element={<PageCategory Chronicle="Ertheia" />} />
  <Route path="/InfiniteOdyssey" element={<PageCategory Chronicle="Infinite Odyssey" />} />
  <Route path="/Underground" element={<PageCategory Chronicle="Underground" />} />
  <Route path="/Salvation" element={<PageCategory Chronicle="Salvation" />} />
          <Route 
            path="/AllServers"
            element={<PageCategory   />}
          />
          <Route path="/LoginSignup" element={<LoginSignup />} />
          <Route path="/server" element={<Server />} />
          <Route path="/server/:name" element={<Server />} />
          <Route path="/MyAccount" element={<MyAccount />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path='/forgot-password' element={<ForgotPassword/>}/>
          <Route 
            path="/BuyCoins"
            element={
              <Elements stripe={stripePromise}>
                <BuyCoins />
              </Elements>
            }
          />
      <Route path="/buybanner" element={<PrivateRoute element={BuyBanner} />} />
      <Route path="/AddMyServer" element={<PrivateRoute element={AddMyServer} />} />

       
          {/* Route for search area */}
          <Route path="/EditAccount" element={<PrivateRoute element={EditAccount} />} />


            {/* Nested route for search results */}
            <Route path="/search" element={<Search />} />
            <Route path='/TermsOfUse' element={<TermsOfUse/>} />
           <Route path='/CookiesPolicy' element={<CookiesPolicy />}/>
           <Route path='/DataPolicy' element={<DataPolicy />}/>
           
        </Routes>
        </main>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
