import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
export const Footer = () => {
  return (
    <div className='Footer'><footer id='footer'> <h1> New L2 Servers -Lineage2 Servers </h1>
    Premium L2 servers, that are coming soon or recently opened. Don't miss opening of new L2 servers today!
    
    Here are the highest-quality projects. . Starting to play on such projects you can be sure in their stability and good online.
    
    All new Lineage 2 private servers are placed in the list, which we update daily, providing the latest information about new openings. A  search engine will let you find a project suitable for you in a few seconds. Lineage2 servers, which are displayed at the top are the best L2 servers
    
    We place only basic information: link to the project, rates, chronicles and opening date. Detailed information of each server L2 can be found on their sites. There you can find instructions for entering the game.
    
    On our site you can find such servers as: PvP, low rate, GvE, RvR, MultiCraft, MultiSkill, classic version of the game and much more.
    <div>
      <Link to='/CookiesPolicy' className='footer-link'> Cookies Policy</Link>
      <Link to='/TermsOfUse' className='footer-link'>Terms of Use</Link>
      <Link to='/DataPolicy' className='footer-link'>Data Policy</Link>
        <a className='footer-link' href="https://discord.gg/2GvpHvuUz8">Discord</a>

    </div>
    </footer>
    </div>
  )
}
