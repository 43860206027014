import React, { useContext, useEffect, useState } from 'react';
import './Home.css';
import test from '../Assets/test2.jpg';
import ServersData from '../Assets2/ServersData'; // Renamed the imported variable to avoid naming conflicts
import Button from '../ButtonColour/ButtonColour';
import { ServersContext } from '../ServersContext';
import { Link } from 'react-router-dom';
import useBannerPositioning from '../BannerPositioning';
function BoxWithServers(props) {
  const [servers, setServers] = useState([]);
  const [PremiumServers, setPremiumServers] = useState([]);
  const [serverAdded, setServerAdded] = useState(false);
  const chronicles = [
    { name: 'Interlude', buttonClass: 'custom-btn-interlude', link: '/Interlude' },
    { name: 'H5', buttonClass: 'custom-btn-h5', link: '/H5' },
    { name: 'Classic', buttonClass: 'custom-btn-classic', link: '/Classic' },
    { name: 'Essence', buttonClass: 'custom-btn-essence', link: '/Essence' },
    { name: 'Hellbound', buttonClass: 'custom-btn-hellbound', link: '/Hellbound' },
    { name: 'Gracia', buttonClass: 'custom-btn-gracia', link: '/Gracia' },
    { name: 'Gracia Final', buttonClass: 'custom-btn-gracia-final', link: '/GraciaFinal' },
    { name: 'Gracia Epilogue', buttonClass: 'custom-btn-gracia-epilogue', link: '/GraciaEpilogue' },
    { name: 'Freya', buttonClass: 'custom-btn-freya', link: '/Freya' },
    { name: 'GoD', buttonClass: 'custom-btn-god', link: '/GoD' },
    { name: 'Grand Crusade', buttonClass: 'custom-btn-grand-crusade', link: '/GrandCrusade' },
    { name: 'Helios', buttonClass: 'custom-btn-helios', link: '/Helios' },
    { name: 'Fafurion', buttonClass: 'custom-btn-fafurion', link: '/Fafurion' },
    { name: 'Prelude of War', buttonClass: 'custom-btn-prelude-of-war', link: '/PreludeOfWar' },
    { name: 'Homunculus', buttonClass: 'custom-btn-homunculus', link: '/Homunculus' },
    { name: 'Olympiad', buttonClass: 'custom-btn-olympiad', link: '/Olympiad' },
    { name: 'Prelude', buttonClass: 'custom-btn-prelude', link: '/Prelude' },
    { name: 'The Kamael', buttonClass: 'custom-btn-the-kamael', link: '/TheKamael' },
    { name: 'Goddess of Destruction', buttonClass: 'custom-btn-goddess-of-destruction', link: '/GoddessOfDestruction' },
    { name: 'Awakening', buttonClass: 'custom-btn-awakening', link: '/Awakening' },
    { name: 'Harmony', buttonClass: 'custom-btn-harmony', link: '/Harmony' },
    { name: 'Tauti', buttonClass: 'custom-btn-tauti', link: '/Tauti' },
    { name: 'Glory Days', buttonClass: 'custom-btn-glory-days', link: '/GloryDays' },
    { name: 'Lindvior', buttonClass: 'custom-btn-lindvior', link: '/Lindvior' },
    { name: 'Valiance', buttonClass: 'custom-btn-valiance', link: '/Valiance' },
    { name: 'Ertheia', buttonClass: 'custom-btn-ertheia', link: '/Ertheia' },
    { name: 'Infinite Odyssey', buttonClass: 'custom-btn-infinite-odyssey', link: '/InfiniteOdyssey' },
    { name: 'Underground', buttonClass: 'custom-btn-underground', link: '/Underground' },
    { name: 'Salvation', buttonClass: 'custom-btn-salvation', link: '/Salvation' }
  ];
  
  useEffect(() => {
    const fetchPremiumServers = async () => {
      try {
        const response = await fetch('https://l2info.eu/api/servers'); // Adjust the URL to match your backend endpoint
        if (response.ok) {
          const data = await response.json();
          setServers(data.servers || []); // Assuming data.servers should be an array
          setPremiumServers(data.servers.filter(server => server.Premium));
        } else {
          throw new Error('Failed to fetch premium servers');
        }
      } catch (error) {
        console.error('Error fetching premium servers:', error);
      }
    };

    fetchPremiumServers();
  }, []);
const number=1;
const increasingnumber=number+1;
const currentDate = new Date();

const sortByOpeningDate = [...servers]
  .filter(server => new Date(server.OpeningDate) < currentDate) // Filter out upcoming servers
  .sort((a, b) => new Date(b.OpeningDate) - new Date(a.OpeningDate)); // Sort the remaining servers by OpeningDate
const sortByUpcomingDate =[...servers].filter(server =>new Date(server.OpeningDate)> currentDate).sort((a, b) => new Date(a.OpeningDate) - new Date(b.OpeningDate)); // Sort the remaining servers by OpeningDate

  const Top10NewServers = sortByOpeningDate.slice(0, 10).map((server, index) => (
    <div className='container mt-3' key={index}>
      <div className='row mb-2'>
        <div className='col-1'>
          <span className='badge custom-badge'>{index + 1}</span>
        </div>
        <div className='col'>
          <Link className='btn custom-link' to={`/Server/${server.name}`}>
            {server.name}
          </Link>
        </div>
        {/* Loop through chronicles array */}
        {chronicles.map(chronicle => (
        server.Chronicle === chronicle.name && ( // Exact string match
          <div className='col col-md-3 mr-lg-4' key={chronicle.name}>
            <Link to={chronicle.link}>
              <button className={`btn custom-btn ${chronicle.buttonClass}`}>{chronicle.name}</button>
            </Link>
          </div>
        )
      ))}
        {/* Add a new div to display the opening date */}
        <div className='col col-md-3'>
        <span className='badge custom-badge small text-sm' style={{ fontSize: '0.6rem' }}>
  {new Date(server.OpeningDate).toLocaleDateString()}
</span>

        </div>
      </div>
    </div>
  ));
  
  const Top10UpcomingServers = sortByUpcomingDate.slice(0, 10).map((server, index) => (
    <div className='container mt-3' key={index}>
      <div className='row mb-2'>
        <div className='col-1'>
          <span className='badge custom-badge'>{index + 1}</span>
        </div>
        <div className='col'>
          <Link className='btn custom-link' to={`/Server/${server.name}`}>
            {server.name}
          </Link>
        </div>
        {/* Loop through chronicles array */}
        {chronicles.map(chronicle => (
        server.Chronicle === chronicle.name && ( // Exact string match
          <div className='col col-md-3 mr-lg-4' key={chronicle.name}>
            <Link to={chronicle.link}>
              <button className={`btn custom-btn ${chronicle.buttonClass}`}>{chronicle.name}</button>
            </Link>
          </div>
        )
      ))}
        {/* Add a new div to display the opening date */}
        <div className='col col-md-3'>
        <span className='badge custom-badge small text-sm' style={{ fontSize: '0.6rem' }}>
  {new Date(server.OpeningDate).toLocaleDateString()}
</span>

        </div>
      </div>
    </div>
  ));

  const sortByVoteCount = [...PremiumServers].sort((a,b)=> b.votes-a.votes);

  const boxes = sortByVoteCount.slice(0, 10).map((server, index) => (
    <div className='container mt-3' key={index}>
      <div className='row mb-2'>
        <div className='col-1'>
          <span className='badge custom-badge'>{index + 1}</span>
        </div>
        <div className='col'>
          <Link className='btn custom-link' to={`/Server/${server.name}`}>
            {server.name}
          </Link>
        </div>
        {/* Loop through chronicles array */}
        {chronicles.map(chronicle => (
        server.Chronicle === chronicle.name && ( // Exact string match
          <div className='col col-md-3 mr-lg-4 ' key={chronicle.name}>
            <Link to={chronicle.link}>
              <button className={`btn custom-btn ${chronicle.buttonClass}`}>{chronicle.name}</button>
            </Link>
          </div>
        )
      ))}
        {/* Add a new div to display the opening date */}
        
      </div>
    </div>
  ));
  


  const newServers = [...PremiumServers].sort((a, b) => new Date(b.OpeningDate) - new Date(a.OpeningDate));
  const sortednewServers = newServers.slice(0, 10).map((server, index) => (
    <div className='container mt-3' key={index}>
      <div className='row mb-2'>
        <div className='col-1'>
          <span className='badge custom-badge'>{index + 1}</span>
        </div>
        <div className='col'>
          <Link className='btn custom-link' to={`/Server/${server.name}`}>
            {server.name}
          </Link>
        </div>
        {/* Loop through chronicles array */}
        {chronicles.map(chronicle => (
        server.Chronicle === chronicle.name && ( // Exact string match
          <div className='col col-md-3 mr-lg-4' key={chronicle.name}>
            <Link to={chronicle.link}>
              <button className={`btn custom-btn ${chronicle.buttonClass}`}>{chronicle.name}</button>
            </Link>
          </div>
        )
      ))}
        {/* Add a new div to display the opening date */}
        <div className='col col-md-3'>
        <span className='badge custom-badge small text-sm' style={{ fontSize: '0.6rem' }}>
  {new Date(server.OpeningDate).toLocaleDateString()}
</span>

        </div>
      </div>
    </div>
  ));
  
  return [boxes,sortednewServers,Top10NewServers,Top10UpcomingServers];
     


  }
  
 
export const All_servers = () => {
  
  const [boxes,sortednewServers,Top10NewServers,Top10UpcomingServers]=BoxWithServers();
  const {handleAddServer}=useContext(ServersContext);
  const {handleAddBanner}=useContext(ServersContext);
  const {spot}=useContext(ServersContext);
  const {banner}=useContext(ServersContext);
  const [spots, setSpots] = useState([]);
  const { userInput } = useContext(ServersContext);
  useEffect(() => {
    const fetchSpots = async () => {
      try {
        const response = await fetch('https://l2info.eu/api/advertising/spots');
        if (!response.ok) {
          throw new Error('Failed to fetch spots');
        }
        const data = await response.json();
    
        console.log('Fetched data:', data);
    
        // Example: Check if data is wrapped in an object with an array property
        if (data && data.spots && Array.isArray(data.spots)) {
          setSpots(data.spots);
        } else if (Array.isArray(data)) {
          setSpots(data);
        } else {
          console.error('Data is not an array or not in the expected format:', data);
        }
    
      } catch (error) {
        console.error('Error fetching spots:', error);
      }
    };
    
  
    fetchSpots();
  }, []);
  
    

  const handleAddServerClick = (spotId) => {
    // Call handleAddServer with the spot ID
    handleAddServer(spotId)
      .then(() => {
        // Handle success
        console.log('Spot availability updated successfully');
        // Update the spot in the local state to mark it as occupied
        setSpots((prevSpots) =>
          prevSpots.map((spot) => {
            if (spot.id === spotId) {
              console.log(`Spot ${spotId} is now occupied`);
              return { ...spot, occupied: true , };
            } else {
              return spot;
            }
          })
        );
      })
      .catch((error) => {
        // Handle error
        console.error('Error updating spot availability:', error);
      });
  };
 

const banners=useContext(ServersContext);
const banners2= banners.banners



 


const baseURL = 'https://l2info.eu/api/';
const hasLeftBanner = banners2.some(element => element.name === 'Left Banner' && !element.available);
const hasRightBanner = banners2.some(element => element.name === 'Right Banner' && !element.available);
const hasBottomBanner1 = banners2.some(element => element.name === 'Home BottomBanner 1' && !element.available);
const hasBottomBanner2 = banners2.some(element => element.name === 'Home BottomBanner 2' && !element.available);
const hasBottomBanner3 = banners2.some(element => element.name === 'Home BottomBanner 3' && !element.available);
const hasBottomBanner4 = banners2.some(element => element.name === 'Home BottomBanner 4' &&  !element.available);
/* useEffect(() => {
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(null, args), wait);  // Changed 'context' to 'null' since 'this' is undefined in hooks
    };
  };

  const handleScroll = debounce(() => {
    const footer = document.getElementById("footer");
    if (!footer) return;

    const banners = document.querySelectorAll(".pagebanner");
    const footerRect = footer.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const footerVisible = footerRect.top < windowHeight;
    const rightBanner = document.querySelector(".right-banner");
     const scrollTop = window.scrollY; // Get current vertical scroll position from the top
    console.log("Scrolled Pixels: ", scrollTop); // Log how many pixels have been scrolled
    banners.forEach((banner) => {
       if (footerVisible) {
        banner.style.position = "absolute";
        banner.style.top = "auto"; // Ensure top is auto to use bottom
          banner.style.bottom = `${windowHeight - footerRect.Top}px`;
      } else {
        banner.style.position = "fixed";
        banner.style.top = "70px";
      }
    });
  }, 100);

  window.addEventListener("scroll", handleScroll);
  handleScroll();

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);
 */
 
  return (
    <div className="home ">
      {/* Container for Left and Right Banners */}
      <div   className="container-fluid">
        <div className="row page-content col-12 combinatio">
          {/* Left Banner */}
          <div className=" col-md-3 col-lg-3 col-sm-1 leftcol "> {/* 25% width on medium and larger screens */}
            {hasLeftBanner ? (
              banners2.map((element, index) =>
                element.name === 'Left Banner' && !element.available ? (
                  <div key={index} className='pagebanner left-banner col-12 col md-12 col-lg-12'>
                    <a href={`${element.website}`} className='testimg' target="_blank" rel="noopener noreferrer">
                      <img className='xaxare img-fluid' crossOrigin='anonymous' src={`${baseURL}${element.image}`}   alt="Left Banner"   loading="lazy"  
                      />
                    </a>
                  </div>
                ) : null
              )
            ) : (
              <div className='pagebanner left-banner'>
                <Link className='bannerlink' to={{ pathname: "/BuyBanner" }} >
                  <button className='bannerbutton' >
                    Add your ServerBanner by clicking here!
                    573x1100px
                  </button>
                </Link>
              </div>
            )}
          </div>

          {/* Center Content */}
          <div className="col-md-12 col-lg-6"> {/* 50% width on medium and larger screens */}
            {/* Message Section */}
             

            {/* Premium Servers Section */}
            <div className="container py-4">
  <div className="row">
  <div className="col-lg-6 col-md-12 mb-4">
  <div className="box-prem-box2 p-3 border rounded" style={{ minHeight: '500px' }}>
    <p className="ptag fs-3 text-center mb-4 custom-p" style={{ minHeight: '30px' }}>
      <em>Premium Servers</em>
    </p>
    <div>
      {spots.slice(0, 4).map((spot) => (
        <div className="premboxbutton mb-3" key={spot.id} style={{ minHeight: '50px' }}>
          {!spot.available ? (
            <a href={`http://${spot.website}`} target="_blank" rel="noopener noreferrer">
              <button 
                className='btn brobutton w-100' 
                style={{ 
                  backgroundColor: spot.selectedColor, 
                  minHeight: '40px',
                  padding: '10px',
                  lineHeight: '20px' 
                }}>
                {spot.textdisplay}
              </button>
            </a>
          ) : (
            <Link to={{ pathname: "/BuyBanner", state: { spotId: spot.id } }}>
              <button 
                className='btn spotbutton w-100' 
                style={{ 
                  minHeight: '40px',
                  padding: '10px',
                  lineHeight: '20px' 
                }}
                onClick={() => handleAddServerClick(spot.id)}>
                Add your server here
              </button>
            </Link>
          )}
        </div>
      ))}
    </div>
    {boxes}
  </div>
</div>

    <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
      <div className="box-prem-box2 p-3 border rounded">
        <p className="ptag fs-3 text-center mb-4 custom-p" style={{ minHeight: '30px' }}>
          <em>New Premium Servers</em>
        </p>
        <div>
          {spots.slice(4, 8).map((spot) => (
            <div className="premboxbutton mb-3" key={spot.id}>
              {!spot.available ? (
                <a href={`http://${spot.website}`} target="_blank" rel="noopener noreferrer">
                  <button className='btn brobutton w-100' style={{ backgroundColor: spot.selectedColor }}>
                    {spot.userInput}
                  </button>
                </a>
              ) : (
                <Link to={{ pathname: "/BuyBanner", state: { spotId: spot.id } }}>
                  <button className='btn spotbutton w-100' onClick={() => handleAddServerClick(spot.id)}>
                    Add your server here
                  </button>
                </Link>
              )}
            </div>
          ))}
          {sortednewServers}
        </div>
      </div>
    </div>
    <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
      <div className="box-prem-box2 p-3 border rounded">
        <p className="ptag fs-3 text-center mb-4 custom-p" style={{ minHeight: '30px' }}>
          <em>New Servers</em>
        </p>
        
          {Top10NewServers}
        
      </div>
    </div>
    <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
      <div className="box-prem-box2 p-3 border rounded">
        <p className="ptag fs-3 text-center mb-4 custom-p" style={{ minHeight: '30px' }}>
          <em>Upcoming Servers</em>
        </p>
        
          {Top10UpcomingServers}
        
      </div>
    </div>
  </div>
</div>

          </div>

          {/* Right Banner */}
          <div className="col-md-3 col-lg-3 my-sweet-banner"> {/* 25% width on medium and larger screens */}
            {hasRightBanner ? (
              banners2.map((element, index) =>
                element.name === 'Right Banner' && !element.available ? (
                  <div key={index} className='pagebanner right-banner my-sweet-banner'>
                    <img className='testimg' src={`${baseURL}${element.image}`} crossorigin="anonymous" alt="Right Banner" loading='lazy'/>
                  </div>
                ) : null
              )
            ) : (
              <div className='pagebanner right-banner my-sweet-banner col-12 col-lg-12 col-md-12 '>
                <Link className='bannerlink' to={{ pathname: "/BuyBanner" }} >
                  <button className='bannerbutton' >
                    Add your ServerBanner by clicking here!
                    561x1100px
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Bottom Banners Section */}
      <div className="container-fluid  ">
      <div className="col justify-content-center align-items-center">
      {/* Home BottomBanner 1 */}
      <div className="col-12 d-flex justify-content-center align-items-center full-width d-none d-md-flex">
  {hasBottomBanner1 ? (
    banners2.map((element, index) =>
      element.name === 'Home BottomBanner 1' && !element.available ? (
        <a key={index} href={`${element.website}`} target="_blank" rel="noopener noreferrer">
          <img className="xaxaphoto img-fluid custom-banner" src={`${baseURL}${element.image}`} crossorigin="anonymous" alt="Bottom Banner 1" />
        </a>
      ) : null
    )
  ) : (
    <Link className="axrefbannerbot" to={{ pathname: "/BuyBanner" }}>
      <button className="brobutton2 btn btn-primary" onClick={() => handleAddServerClick(banners2.id)}>
        Add your serverBanner here!<br /> 1100 x 150 px
      </button>
    </Link>
  )}
</div>



    {/* Home BottomBanner 2 */}
    <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center d-none d-md-flex">
      {hasBottomBanner2 ? (
        banners2.map((element, index) =>
          element.name === 'Home BottomBanner 2' && !element.available ? (
            <a key={index} href={`${element.website}`} target="_blank" rel="noopener noreferrer">
              <img className='xaxaphoto img-fluid' src={`${baseURL}${element.image}`} crossorigin="anonymous" alt="Bottom Banner 2" />
            </a>
          ) : null
        )
      ) : (
        <Link className='axrefbannerbot' to={{ pathname: "/BuyBanner" }}>
          <button className='brobutton2' onClick={() => handleAddServerClick(banners2.id)}>
            Add your serverBanner here!
            1100 x 150 px
          </button>
        </Link>
      )}
    </div>

    {/* Home BottomBanner 3 */}
    <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center d-none d-md-flex">
      {hasBottomBanner3 ? (
        banners2.map((element, index) =>
          element.name === 'Home BottomBanner 3' && !element.available ? (
            <a key={index} href={`${element.website}`} target="_blank" rel="noopener noreferrer">
              <img className='xaxaphoto img-fluid' src={`${baseURL}${element.image}`} crossorigin="anonymous" alt="Bottom Banner 3" />
            </a>
          ) : null
        )
      ) : (
        <Link className='axrefbannerbot' to={{ pathname: "/BuyBanner" }}>
          <button className='brobutton2' onClick={() => handleAddServerClick(banners2.id)}>
            Add your serverBanner here!
            1100 x 150 px
          </button>
        </Link>
      )}
    </div>

    {/* Home BottomBanner 4 */}
    <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center d-none d-md-flex">
      {hasBottomBanner4 ? (
        banners2.map((element, index) =>
          element.name === 'Home BottomBanner 4' && !element.available ? (
            <a key={index} href={`${element.website}`} target="_blank" rel="noopener noreferrer">
              <img className='xaxaphoto img-fluid col-12' src={`${baseURL}${element.image}`} crossorigin="anonymous" alt="Bottom Banner 4" />
            </a>
          ) : null
        )
      ) : (
        <Link className='axrefbannerbot' to={{ pathname: "/BuyBanner" }}>
          <button className='brobutton2' onClick={() => handleAddServerClick(banners2.id)}>
            Add your serverBanner here!
            1100 x 150 px
          </button>
        </Link>
      )}
    </div>
   </div>
</div>

      
    </div>
  );
};