import React from 'react';
import { Helmet } from 'react-helmet';

const Helmett = () => {
    return (
        <div>
            <Helmet>
                <meta
                    http-equiv="Content-Security-Policy"
                    content="
                        default-src 'self' https://l2info.eu https://www.l2info.eu;
                        script-src 'self' https://l2info.eu https://www.l2info.eu https://js.stripe.com;
                        style-src 'self' https://l2info.eu https://fonts.googleapis.com https://stackpath.bootstrapcdn.com https://cdnjs.cloudflare.com;
                        img-src 'self' https://l2info.eu https://www.l2info.eu https://www.l2info.eu/  https://l2info.eu/ data:;
                        form-action 'self' https://l2info.eu https://www.l2info.eu;
                        frame-ancestors 'self' https://l2info.eu https://www.l2info.eu;
                        frame-src 'self' https://l2info.eu https://www.l2info.eu https://js.stripe.com;
                        connect-src 'self' https://l2info.eu https://www.l2info.eu https://l2info.eu/api https://www.l2info.eu/api https://r.stripe.com https://api.stripe.com;
                        font-src 'self' https://fonts.gstatic.com;
                    "
                />
            </Helmet>

            {/* Your component content */}
        </div>
    );
};

export default Helmett;
