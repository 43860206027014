import React from 'react'
import './TermsOfUse.css'
export const CookiesPolicy = () => {
  return (
    <div className='termsdiv'>Cookies are small text files that are placed on your device to store data and can be retrieved by a web server in the domain where the cookie was placed. We use cookies and similar technologies to store and respect your preferences and settings, to combat fraud, to analyze the performance of our products and for other legitimate purposes, as described below.

    The site www.l2info.eu can use cookies as part of the facility, but also the operation of services through its website, making browsing more user friendly. Cookies are small files (text files) that are sent and stored on the computer used by the user, allowing websites to operate smoothly and without technical anomalies, to collect multiple user options, to identify frequent users, to facilitate access as well as for statistical purposes, to determine the areas in which the services of the website are useful, popular or for marketing purposes.
    
    You must keep in mind that cookies are necessary for our website to function properly and smoothly. The information stored in the cookies of our website is used exclusively by www.l2info.eu but also by cooperating companies such as Google for statistical purposes.
    
    Our website provides more settings options for individual cookies. Furthermore, the user can configure his server so that he does not receive cookies, either in total or on a case-by-case basis or choose to delete them automatically when leaving the website.  </div>
  )
}
