import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ServerDisplay } from '../ServerDisplay/ServerDisplay';

export const Server = () => {
  const { name } = useParams();
  const [server, setServer] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServer = async () => {
      try {
        const response = await fetch(`https://l2info.eu/api/servers`); // Fetch data for all servers
        if (response.ok) {
          const data = await response.json();
          console.log('Fetched server data:', data); // Log the fetched server data
          const matchedServer = data.servers.find(server => server.name === name); // Filter server by name
          setServer(matchedServer);
        } else {
          throw new Error('Failed to fetch server');
        }
      } catch (error) {
        console.error('Error fetching server:', error);
      } finally {
        setLoading(false);
      }
    };

    console.log('Fetching server:', name); // Log the name of the server being fetched
    fetchServer();
  }, [name]);

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : server ? (
        <ServerDisplay server={server} />
      ) : (
        <div>Server not found</div>
      )}
    </div>
  );
};
