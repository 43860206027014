import React, { useContext, useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ServersContext } from '../ServersContext';
import './CSS/BuyCoins.css';
import ProgressBar from 'react-bootstrap/ProgressBar'; // Ensure react-bootstrap is installed

const stripePromise = loadStripe('pk_live_51JMqiOGlZZjBreoNFTyW4SglzyoLlFnsrAYyFCmclQwftFSe2FAZ2aJX2ZLWDNFoT9Tkz6DraDuKuqrNklBZHFMP00Tf3EG49A');

export const BuyCoins = () => {
  const stripe = useStripe();
  const servContext = useContext(ServersContext);
  const [sessionId, setSessionId] = useState('');
  const [amount, setAmount] = useState(1); // State to manage the amount of coins
  const [discount, setDiscount] = useState(0);
  const data = useContext(ServersContext);
  const csrfToken = data.csrfToken;

  // Define discount thresholds
  const thresholds = [
    { amount: 35, discount: 5 },
    { amount: 50, discount: 5 },
    { amount: 100, discount: 5 },
    { amount: 200, discount: 10 },
    { amount: 300, discount: 15 },
    { amount: 500, discount: 20 },
    { amount: 700, discount: 25 },
    { amount: 1000, discount: 30 },
    { amount: 1300, discount: 35 },
    { amount: 1600, discount: 40 },
    { amount: 2000, discount: 45 },
  ];
  
  // Calculate discount based on coin amount
  const calculateDiscount = (amount) => {
    let applicableDiscount = 0;
  
    for (const threshold of thresholds) {
      if (amount >= threshold.amount) {
        applicableDiscount = threshold.discount;
      } else {
        // No need to continue if amount is less than the current threshold
        break;
      }
    }
  
    return applicableDiscount;
  };
  
  useEffect(() => {
    const newDiscount = calculateDiscount(amount);
    setDiscount(newDiscount);
    console.log('Calculated Discount:', newDiscount); // Add this line to check the discount
  }, [amount]);

  // Define progress bar value
  const maxThresholdAmount = 2000; // Use the maximum threshold value for full progress
  const progressBarValue = Math.min((amount / maxThresholdAmount) * 100, 100);

  // Handle Stripe checkout
  const redirectToCheckout = async () => {
    try {
      const stripe = await stripePromise;
      const response = await fetch('https://l2info.eu/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('auth-token')}`,
          'CSRF-Token': csrfToken, // Include the CSRF token here
        },
        body: JSON.stringify({
          userId: servContext.storeUserId,
          amount: amount,
          discount: discount
        }),
        credentials: 'include', // Ensure credentials are sent
      });

      const session = await response.json();
      setSessionId(session.id);

      // Redirect to the Stripe Checkout page
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error('Error redirecting to Checkout:', result.error);
      }
    } catch (error) {
      console.error('Error processing checkout:', error);
    }
  };

  return (
    <div className='BuyCoins'>
      <div className='containerco'>
        <div className='coin-monitor'>
          <h3>Amount of Coins: {amount}</h3>
          <ProgressBar now={progressBarValue} />
          <h4>Discount Available: {discount}%</h4>
          <p>currently saving: {amount * (0.01 * discount)} USD</p>
          <p>Amount you will pay: {amount - amount * (0.01 * discount)} USD</p>
        </div>
        <p className='text'>Insert amount of coins you want to purchase</p>
        <input
          className='inputcoins'
          type="number"
          min="1"
          value={amount}
          onChange={(e) => setAmount(parseInt(e.target.value, 10))}
        />
        <button className='purchbutton' onClick={redirectToCheckout}>
          Purchase Coins
        </button>
      </div>
    </div>
  );
};

