import React from 'react'
import './TermsOfUse.css'
export const DataPolicy = () => {
  return (
    <div className='termsdiv'> <h1>Personal data protection policy</h1>
    The protection of your personal data is very important to us. We are committed to protecting and safeguarding any personal information you provide to us as well as the information we collect while visiting our website. The purpose of publishing our policy on this sensitive issue is to inform the prospective user of the website in advance of the information and personal data we collect on our website, how we use it, how we protect it and what privacy choices it has of.
    
    Our office reserves the unilateral right to change this policy. For this reason, we invite you to be regularly informed about our privacy policy.
    
    <h2>1. WHAT IS PERSONAL DATA</h2>
    Personal data is information that can be used to identify a person. In addition to such primary information, other secondary information related to a specific individual is often important (such as, for example, his sources and level of income, his habits in general, etc.)
    
    WE collect and processe specific data for the purpose of rendering legal services. Most of them are provided to us by the clients themselves or on his behalf and are related to the handling of their cases.
    
    Protecting all this data is important to us. Our goal is to be as honest and transparent as possible about the personal data we collect and how we process it under the EU General Data Protection Regulation (known as the “GDBR”). Our firm respects your concern about your personal data.
    
    The only kind of personal data we collect is:
    
    your name , if you let us know.
    Contact details such as  email address
    Most likely, in the context of handling your server addition, you will be asked for a Server name,XPrate,Chronicle, etc. the provision of which is always under your consent.
    For voting validation we have to keep your IP address
    We can also collect information from the use of our site, which you do, through COOKIES.
    
   <h2>2. HOW WE PROTECT THEM</h2> 
  We will never, knowingly, give your data to any third party and for absolutely no reason, unless it is a third party, with whom we choose to work to offer us IT services, which, however, are not authorized, nor can they hold, use, or process your data in any way for purposes other than those for which they are assigned. It goes without saying that this information will also be given to the public authorities if it is legally required.
    
  We take  care of the security of the data of the users of its website.
    
   <h2> 3. NEWSLETTER</h2>
    On our website and in the link “CONTACT”, the respective visitor can easily send an e-mail to contact us and discuss the issue that concerns him  .
    
    By sending his e-mail, in which he registers the personal data that are requested, he accepts the registration of his data in a list of recipients of regular messages. Of course, when downloading the Newsletter, it is possible to delete it from the specific list (unsubscribe).
    
    Our office reserves the right to process part or all the data sent by users for statistical purposes and to improve the services – information provided.</div>
  )
}
