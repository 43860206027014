import React, { createContext, useEffect, useState } from 'react';
import Servers from '../Components/Assets2/ServersData';

export const ServersContext = createContext(null);

const ServersContextProvider = (props) => {
  const contextValue = { Servers };
  const [userToken, setUserToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [servData, setServData] = useState([]); // Initialize as an empty array
  const [CreatedSpot, setCreatedSpot] = useState(null); // State to hold the created spot
  const [userInput, setUserInput] = useState('');
  const [selectedSpot, setSelectedSpot] = useState(null);
  const [durationDays, setDurationDays] = useState(1);
  const [website, setWebsite] = useState('');
  const [otherInput, setOtherInput] = useState('');
  const [CreatedBanner, setCreatedBanner] = useState(null);
  const [csrfToken, setCsrfToken] = useState('');
  const [error, setError] = useState(null);
  const [showRelogMessage, setShowRelogMessage] = useState(false);
  const [servUser, setServUser] = useState(null);

  // Fetch the CSRF token
  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch('https://l2info.eu/api/csrf-token', {
          credentials: 'include', // Ensures cookies are included for cross-origin requests
        });
        const data = await response.json();
        setCsrfToken(data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCsrfToken();
  }, []);

  // Fetch user token from localStorage
  useEffect(() => {
    const token = localStorage.getItem('auth-token');
    if (token) {
      setUserToken(token);
    }
  }, []);

  // Fetch user coins
  useEffect(() => {
    const fetchUserCoins = async () => {
      try {

        const response = await fetch('https://l2info.eu/api/user-coins', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
          },
        });


        if (response.status === 304) {
          setLoading(false);
          return;
        }

        if (!response.ok) {
          throw new Error('Unauthorized: Please re-login');
        }

        const data = await response.json();

        if (data.user && data.user._id) {
          setServUser(data.user._id);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user coins:', error);
        if (error.message === 'Unauthorized: Please re-login') {
          setShowRelogMessage(true);
        } else {
          setError('Error fetching user coins');
        }
        setLoading(false);
      }
    };

    fetchUserCoins();
  }, [userToken]);

  const storeUserId = servUser;

  const [availableSpots, setAvailableSpots] = useState([]);

  const Spot = async (spotData) => {
    try {
      const response = await fetch('https://l2info.eu/api/advertising/spots', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include the CSRF token
        },
        body: JSON.stringify(spotData),
      });
      if (!response.ok) {
        throw new Error('Failed to create a new spot');
      }
      const data = await response.json();
      setCreatedSpot(data);
    } catch (error) {
      console.error('Error creating a new spot:', error);
    }
  };

  const banner = async (bannerData) => {
    try {
      const response = await fetch('https://l2info.eu/api/advertising/banners', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include the CSRF token
        },
        body: JSON.stringify(bannerData),
      });
      if (!response.ok) {
        throw new Error('Failed to create a new banner');
      }
      const data = await response.json();
      setCreatedBanner(data);
    } catch (error) {
      console.error('Error creating a new Banner:', error);
    }
  };

  const handleAddServer = async (spotId) => {
    try {
      const response = await fetch(`https://l2info.eu/api/advertising/spots/${spotId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include the CSRF token
        },
        body: JSON.stringify({ available: true }),
      });
      if (!response.ok) {
        throw new Error('Failed to update spot availability');
      }
    } catch (error) {
      console.error('Error updating spot availability:', error);
    }
  };

  const handleAddBanner = async (bannerId) => {
    try {
      const response = await fetch(`https://l2info.eu/api/advertising/spots/${bannerId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include the CSRF token
        },
        body: JSON.stringify({ available: true }),
      });
      if (!response.ok) {
        throw new Error('Failed to update banner availability');
      }
    } catch (error) {
      console.error('Error updating banner availability:', error);
    }
  };

  const fetchAvailableSpots = async () => {
    try {
      const response = await fetch('https://l2info.eu/api/advertising/spots');
      if (!response.ok) {
        throw new Error('Failed to fetch available spots');
      }
      const data = await response.json();
      setAvailableSpots(data);
    } catch (error) {
      console.error('Error fetching available spots:', error);
    }
  };

  const handlePurchase = async (e) => {
    e.preventDefault();
    if (!selectedSpot) {
      console.error('Please select a spot');
      return;
    }

    try {
      const response = await fetch('https://l2info.eu/api/purchase-spot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include the CSRF token
        },
        body: JSON.stringify({
          userId: storeUserId,
          spotId: selectedSpot.id,
          durationDays: durationDays,
          userInput: selectedSpot.userInput,
          website: selectedSpot.website
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to purchase Spot');
      }
      fetchAvailableSpots();
      console.log('Spot purchased successfully');
    } catch (error) {
      console.error('Error purchasing Spot:', error);
    }
  };

  const [banners, setBanners] = useState([]);
  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch('https://l2info.eu/api/advertising/banners2');
        if (!response.ok) {
          throw new Error('Failed to fetch banners');
        }
        const data = await response.json();
        setBanners(data);
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, []);

  const [servers, setServers] = useState([]);

  useEffect(() => {
    const fetchServers = async () => {
      try {
        const response = await fetch('https://l2info.eu/api/servers');
        if (!response.ok) {
          throw new Error('Failed to fetch servers');
        }
        const data = await response.json();
        setServers(data);
      } catch (error) {
        console.error('Error fetching servers:', error);
      }
    };

    fetchServers();
  }, []);

  return (
    <ServersContext.Provider value={{
      contextValue,
      servers,
      storeUserId,
      handleAddServer,
      Spot,
      CreatedSpot,
      setUserInput,
      availableSpots,
      fetchAvailableSpots,
      handlePurchase,
      setWebsite,
      handleAddBanner,
      banner,
      banners,
      csrfToken
    }}>
      {props.children}
    </ServersContext.Provider>
  );
}

export default ServersContextProvider;
