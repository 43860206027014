import React, { useState, useEffect } from 'react';
import '../Components/Pages/CSS/BuyCoins.css'
const UserCoinsComponent = ({ token }) => {
    const [userCoins, setUserCoins] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); // Add error state
    const [showRelogMessage, setShowRelogMessage] = useState(false); // State to control displaying re-login message
    const [ServUser,setServUser]=useState(null);
    useEffect(() => {
        const fetchUserCoins = async () => {
            try {
                const response = await fetch('https://l2info.eu/api/user-coins', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                if (response.status === 304) {
                    // Resource not modified, keep the existing userCoins state
                    setLoading(false);
                    return;
                }

                if (!response.ok) {
                    throw new Error('Unauthorized: Please re-login'); // Throw specific error for re-login
                }

                const data = await response.json();
                setUserCoins(data.coins);
                setServUser(data.user);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user coins:', error);
                if (error.message === 'Unauthorized: Please re-login') {
                    setShowRelogMessage(true); // Set state to show re-login message
                } else {
                    setError('Error fetching user coins'); // Set error state
                }
                setLoading(false);
            }
        };

        fetchUserCoins();
    }, [token]);

    return (
        <div className='testflex'>
            {loading ? (
                <p className='testcoins'>Loading user coins...</p>
            ) : error ? (
                <p>Error: {error}</p> // Display error message if error occurred
            ) : (
                <div className='my-coins'>My coins:
                    {showRelogMessage ? (
                        <p className='testcoins'>Please re-login to view your coins</p> // Display re-login message
                    ) : (
                        <p className='testcoins' >{userCoins !== null ? `${userCoins} coins  ` : 'User coins not available'}</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default UserCoinsComponent;
