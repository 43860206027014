import React, { useContext, useEffect, useState } from 'react';
import { ServersContext } from '../ServersContext';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import Button from '../ButtonColour/ButtonColour';
import SearchArea from '../SearchArea'; // Import the SearchArea component
import Search from '../Pages/Search';
import { useNavigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
export const Navbar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const { availableServers } = useContext(ServersContext); // Assuming you have a context providing availableServers
  const [menu, setMenu] = useState('All_servers');
  const [searchResults, setSearchResults] = useState([]);
  const ServContext = useContext(ServersContext);
  const servers = ServContext.servers.servers;
  const location = useLocation();
  const [dropdownVisible, setDropdownVisible] = useState(false); // State to control dropdown visibility
  const navigate = useNavigate();

  const handleSearch = () => {
    const trimmedQuery = searchQuery.trim();
    if (!trimmedQuery) {
      setSearchResults([]);
      return;
    }

     

    // Update the URL with the query parameter
    navigate(`/search?query=${encodeURIComponent(trimmedQuery)}`);
  };
   const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible); // Toggle dropdown visibility on button click
  };
  const [theme, setTheme] = useState('dark'); // Default theme

  // Update the theme attribute on the <html> element whenever the theme changes
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  // Toggle between light and dark themes
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };
  return (
    <div className="container-fluid my-custom-container">
    <nav className={`navbar navbar-expand-lg ${theme === 'light' ? 'navbar-light-custom' : 'navbar-dark-custom'}`}>
    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item" onClick={() => { setMenu('All_servers') }}>
            <Link className="nav-link" to='/'>Home {menu === 'All_servers' && <hr />}</Link>
          </li>
          <li className="nav-item" onClick={() => { setMenu('Interlude') }}>
            <Link className="nav-link" to='/Interlude'>Interlude {menu === 'Interlude' && <hr />}</Link>
          </li>
          <li className="nav-item" onClick={() => { setMenu('H5') }}>
            <Link className="nav-link" to='/H5'>H5 {menu === 'H5' && <hr />}</Link>
          </li>
          <li className="nav-item" onClick={() => { setMenu('Essence') }}>
            <Link className="nav-link" to='/Essence'>Essence {menu === 'Essence' && <hr />}</Link>
          </li>
          <li className="nav-item" onClick={() => { setMenu('AllServers') }}>
            <Link className="nav-link" to='/AllServers'>All Servers {menu === 'AllServers' && <hr />}</Link>
          </li>
          {localStorage.getItem('auth-token') ? (
            <>
              <li className="nav-item" onClick={() => { setMenu('MyAccount') }}>
                <Link className="nav-link" to='/MyAccount'>My account {menu === 'MyAccount' && <hr />}</Link>
              </li>
              <li className="nav-item" onClick={() => { localStorage.removeItem('auth-token'); window.location.replace('/') }}>
                <Link className="nav-link" to='/'>Logout {menu === 'Logout' && <hr />}</Link>
              </li>
            </>
          ) : (
            <li className="nav-item" onClick={() => { setMenu('LoginSignup') }}>
              <Link className="nav-link" to='/LoginSignUp'>Login/signup {menu === 'LoginSignup' && <hr />}</Link>
            </li>
          )}
          <li className="nav-item dropdown position-relative">
            <span className="nav-link dropdown-toggle" onClick={toggleDropdown}>menu</span>
            {dropdownVisible && (
              <ul className="dropdown-menu show position-absolute w-100">
                <li className="dropdown-item" onClick={() => { setMenu('All_servers') }}>
                  <Link className="nav-link" to='/'>Home</Link>
                </li>
                <li className="dropdown-item" onClick={() => { setMenu('Interlude') }}>
                  <Link className="nav-link" to='/Interlude'>Interlude</Link>
                </li>
                <li className="dropdown-item" onClick={() => { setMenu('H5') }}>
                  <Link className="nav-link" to='/H5'>H5</Link>
                </li>
                <li className="dropdown-item" onClick={() => { setMenu('Essence') }}>
                  <Link className="nav-link" to='/Essence'>Essence</Link>
                </li>
                <li className="dropdown-item" onClick={() => { setMenu('AllServers') }}>
                  <Link className="nav-link" to='/AllServers'>All Servers</Link>
                </li>
                {localStorage.getItem('auth-token') ? (
                  <>
                    <li className="dropdown-item" onClick={() => { setMenu('MyAccount') }}>
                      <Link className="nav-link" to='/MyAccount'>My account</Link>
                    </li>
                    <li className="dropdown-item" onClick={() => { localStorage.removeItem('auth-token'); window.location.replace('/') }}>
                      <Link className="nav-link" to='/'>Logout</Link>
                    </li>
                  </>
                ) : (
                  <li className="dropdown-item" onClick={() => { setMenu('LoginSignup') }}>
                    <Link className="nav-link" to='/LoginSignUp'>Login/signup</Link>
                  </li>
                )}
              </ul>
            )}
          </li>
        </ul>
        
        <SearchArea searchQuery={searchQuery} setSearchQuery={setSearchQuery} handleSearch={handleSearch} />
        <button
          className="btn btn-secondary mb-3 btn-toggle-theme"
          onClick={toggleTheme}
        >
          <i className={`fa ${theme === 'light' ? 'fa-moon' : 'fa-sun'}`}></i>
          {' '}
          
        </button>
      </nav>
       
    </div>
    
  );
};
 