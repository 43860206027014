import React, { useContext, useEffect, useState } from 'react';
import './CSS/EditAccount.css'
import { ServersContext } from '../ServersContext';
function EditAccount() {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);
    const [userToken, setUserToken] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const data=useContext(ServersContext);
    const csrfToken =data.csrfToken;
    useEffect(() => {
        // Fetch the user token from wherever it's stored (e.g., localStorage, sessionStorage)
        const token = localStorage.getItem('auth-token');
        if (token) {
            setUserToken(token);
        }
    }, []);

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('https://l2info.eu/api/update', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`, // Replace with actual JWT token
                    'CSRF-Token': csrfToken, // Include the CSRF token here
                   
                },credentials:'include',
                body: JSON.stringify({ name, password })
            });

            const data = await response.json();
            if (!response.ok) {
                if (data.errors) {
                    setValidationErrors(data.errors); // Set validation errors received from backend
                } else {
                    setErrorMessages([data.error || 'Unknown error occurred']);
                }
                return;
            }

            // Handle success scenario
            alert('Details Updated!')
            console.log('User details updated successfully');
            // Clear form fields and error messages on success
            setName('');
            setPassword('');
            setErrorMessages([]);
            setValidationErrors([]);
        } catch (error) {
            console.error('Error updating user details:', error.message);
            setErrorMessages(['Failed to update user details']);
        }
    };

    return (
        <form onSubmit={handleFormSubmit}>
            {/* Display name input */}
            <div>
                <label className='labeldisplay'> New Name:</label>
                <input className='accountinput' type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            {/* Display password input */}
            <div>
                <label className='labeldisplay'> New Password:</label>
                <input className='accountinput' type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>

            {/* Display password validation errors if any */}
            {validationErrors.length > 0 && (
                <div style={{ color: 'red' }}>
                    <p>Password validation failed:</p>
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}

            {/* Display other errors if any */}
            {errorMessages.length > 0 && (
                <div style={{ color: 'red' }}>
                    <p>Error: {errorMessages.join(', ')}</p>
                </div>
            )}

            {/* Submit button */}
            <button type="submit">Update Details</button>
        </form>
    );
}

export default EditAccount;
