import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import UserCoinsComponent from '../UserCoinsComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CSS/MyAccount.css';
import { ServersContext } from '../ServersContext';

const MyAccount = () => {
  const [userToken, setUserToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [servData, setServData] = useState([]);
  const [servUser, setServUser] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [selectedServer, setSelectedServer] = useState(null); // State to hold selected server
  const [error, setError] = useState(null);
  const [premiumUntil, setPremiumUntil] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false); // State to toggle API key visibility
  const data = useContext(ServersContext);
  const csrfToken = data.csrfToken;

  useEffect(() => {
    const token = localStorage.getItem('auth-token');
    if (token) {
      setUserToken(token);
    }
  }, []);

  useEffect(() => {
    const fetchUserCoins = async () => {
      try {
        const response = await fetch('https://l2info.eu/api/user-coins', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Unauthorized: Please re-login');
        }

        const data = await response.json();

        if (data.user && data.user._id) {
          setServUser(data.user);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user coins:', error);
        setLoading(false);
        setError('Error fetching user coins');
      }
    };

    fetchUserCoins();
  }, [userToken]);

  useEffect(() => {
    const fetchUserServers = async () => {
      try {
        if (!servUser || !servUser._id) return;

        const serversResponse = await fetch(`https://l2info.eu/api/users/${servUser._id}/servers`);
        if (!serversResponse.ok) {
          throw new Error('Failed to fetch user servers');
        }

        const serversData = await serversResponse.json();
        setServData(serversData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user servers:', error);
        setLoading(false);
        setError('Error fetching user servers');
      }
    };

    fetchUserServers();
  }, [servUser]);

  const handleVoteBump = async () => {
    const selectedServerId = document.getElementById('serverSelect').value;
    try {
      const response = await fetch(`https://l2info.eu/api/users/${servUser._id}/add-votes/${selectedServerId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
          'CSRF-Token': csrfToken,
        },
        credentials: 'include'
      });

      const responseData = await response.json();
      setTimeRemaining(responseData.timeRemaining);

      if (!response.ok) {
        if (response.status === 403) {
          const error = responseData.error;
          setTimeRemaining(responseData.timeRemaining);
        } else {
          throw new Error(responseData.error || 'Failed to add votes');
        }
      }
    } catch (error) {
      console.error('Error adding votes:', error);
      setError('Failed to add votes');
    }
  };

  const handlePremiumUpdate = async () => {
    if (!selectedServer || !selectedServer._id) {
      setError('No server selected');
      return;
    }

    try {
      const response = await fetch(`https://l2info.eu/api/update-server/${selectedServer._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
          'CSRF-Token': csrfToken,
        },
        credentials: 'include',
        body: JSON.stringify({ Premium: true })
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log('Server Premium status updated successfully');
        setSelectedServer(prevServer => ({
          ...prevServer,
          Premium: !prevServer.Premium,
          premiumUntil: responseData.premiumUntil
        }));
        setPremiumUntil(responseData.premiumUntil);
      } else {
        throw new Error(responseData.error || 'Failed to update Premium status');
      }
    } catch (error) {
      console.error('Error updating Premium status:', error);
      setError('Failed to update Premium status');
    }
  };

  const handleServerSelect = (event) => {
    const serverId = event.target.value;
    const server = servData.servers.find(server => server._id === serverId);
    setSelectedServer(server);
    setShowInfo(false);
    setShowApiKey(false); // Reset API key visibility when a new server is selected
  };

  const toggleShowInfo = () => {
    setShowInfo(prevShowInfo => !prevShowInfo);
  };

  const toggleShowApiKey = () => {
    setShowApiKey(prevShowApiKey => !prevShowApiKey);
  };

  const showServerInfo = () => {
    if (!selectedServer) return null;

    return (
      <div className="card card-custom mt-3 p-3">
        <div className="card-body">
          <p><strong>Server Name:</strong> {selectedServer.name}</p>
          <p><strong>Premium Status:</strong> {selectedServer.Premium ? 'Active' : 'Inactive'}</p>
          {selectedServer.Premium && selectedServer.premiumUntil && (
            <p><strong>Premium Until:</strong> {new Date(selectedServer.premiumUntil).toLocaleString()}</p>
          )}
          <p className="xaxatexti">Note: If you update premium, you may need to refresh the page to see the new date.</p>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="container my-container">
        <div className="user-coins-container">
          {userToken ? (
            <UserCoinsComponent token={userToken} />
          ) : (
            <p>Loading user coins...</p>
          )}
        </div>

        <div className="d-flex flex-column flex-md-row justify-content-between my-3">
          <Link to='/BuyCoins' className="btn btn-custom-secondary mb-2 mb-md-0 mycustom-button">Buy Coins</Link>
          <Link to='/EditAccount' className="btn btn-custom-secondary mb-2 mb-md-0 mycustom-button">Edit Account</Link>
          <Link to='/BuyBanner' className="btn btn-custom-warning mb-2 mb-md-0 mycustom-button">Buy Banner/Advertise</Link>
          <Link to='/AddMyServer' className="btn btn-custom-info mycustom-button">Add my server</Link>
        </div>

        {loading ? (
          <p>Loading servers...</p>
        ) : (
          <div>
            {servData.servers && servData.servers.length > 0 ? (
              <div>
                <div className="form-group form-group-small">
                  <label htmlFor="serverSelect">Select a server</label>
                  <select id="serverSelect" className="form-control" onChange={handleServerSelect}>
                    <option value="">Select a server</option>
                    {servData.servers.map(server => (
                      <option key={server._id} value={server._id}>{server.name} - {server.Chronicle}</option>
                    ))}
                  </select>
                </div>
                {selectedServer && (
                  <button className="btn btn-custom-info mb-3" onClick={toggleShowInfo}>
                    {showInfo ? 'Hide Info' : 'Show Info'}
                  </button>
                )}
                {showInfo && showServerInfo()}
              </div>
            ) : (
              <p>No servers available</p>
            )}

            {selectedServer && (
              <div>
                <button className="btn btn-custom-success mb-3" onClick={handleVoteBump}>Add Votes</button>
                {timeRemaining && (
                  <p>Time remaining: {timeRemaining.hours} hours, {timeRemaining.minutes} minutes, {timeRemaining.seconds} seconds</p>
                )}

                <button className="btn btn-custom-warning mb-3" onClick={handlePremiumUpdate}>Update Premium</button>
                <p className="mt-2">Note: Buying Premium costs 13 coins for 30 days. If you already have Premium for your server, buying will extend the duration for 30 days costing 13 coins.</p>

                {/* Button to reveal API key */}
                <button className="btn btn-custom-primary" onClick={toggleShowApiKey}>
                  {showApiKey ? 'Hide API Key' : 'Show API Key'}
                </button>

                {/* Display the API key */}
                {showApiKey && (
                  <p><strong>API Key:</strong> {selectedServer.apiKey} to verify vote, go to : L2info.eu/api/verify-vote/api/-APIkey-/ip/-PlayerIPadress-</p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAccount;
