import React, { useContext, useEffect, useState } from 'react';
import './CSS/Search.css';
import banner1 from '../Assets/test2.jpg';
import banner2 from '../Assets2/test.gif';
import { Link, useLocation } from 'react-router-dom';
import Pagination from '../Pagination';
import { ServersContext } from '../ServersContext';

// Function to sanitize input to prevent potential XSS attacks
const sanitizeInput = (input) => {
  return input.replace(/[^a-zA-Z0-9\s]/g, "").trim();
};

const Search = () => {
  const serversPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);
  const [servers, setServers] = useState([]);
  const location = useLocation();
  const query = sanitizeInput(new URLSearchParams(location.search).get('query') || '');
  const [filteredResults, setFilteredResults] = useState([]);


  // Fetch servers from the API
  useEffect(() => {
    const fetchServers = async () => {
      try {
        const response = await fetch('https://l2info.eu/api/servers'); // Ensure this URL is correct
        if (response.ok) {
          const data = await response.json();
          const sortedServers = data.servers.sort((a, b) => b.votes - a.votes);
          setServers(sortedServers);
        } else {
          throw new Error('Failed to fetch servers');
        }
      } catch (error) {
        console.error('Error fetching servers:', error);
      }
    };
    fetchServers();
  }, []);

  // Filter servers based on the sanitized query
  useEffect(() => {
    if (query) {
      const results = servers.filter(server =>
        server.name.toLowerCase().includes(query.toLowerCase()) ||
        server.Chronicle.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  }, [query, servers]);

  // Reset page to 1 when filtered results change
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredResults]);

  // Calculate indices for pagination
  const indexOfLastServer = currentPage * serversPerPage;
  const indexOfFirstServer = indexOfLastServer - serversPerPage;
  const currentServers = filteredResults.slice(indexOfFirstServer, indexOfLastServer);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const baseURL = `https://l2info.eu/api/`;

  const banners = useContext(ServersContext);
  const banners2 = banners.banners;

  const hasLeftBanner = banners2.some(element => element.name === 'Left Banner' && !element.available);
  const hasRightBanner = banners2.some(element => element.name === 'Right Banner' && !element.available);

  // Render server components
  const renderServers = currentServers.map((server, index) => (
    <div className="container mb-3" key={index}>
  <div className="row align-items-center">
    <div className="col-12 col-md-10 col-lg-9 col-sm-11 text-center mb-md-0">
      <div className="d-flex flex-column flex-md-row align-items-center  custom-trash col-12 col-sm-12">
        <Link
          className="btn col-12 col-md-8 col-xl-12 col-lg-10 col-sm-12 server-button pc-custom d-flex align-items-center ml-lg-3"
          to={`/Server/${server.name}`}
        >
          {server.smallBanner && (
            <img crossorigin="anonymous"
              className="img-fluid rounded-circle me-2"
              src={baseURL + server.smallBanner}
              alt={server.name}
            />
          )}
          <span className="text-md-start text-sm-start w-100 align-items-center justify-content-start col-sm-12">
            {server.name} {server.XP} - Votes: {server.votes}
          </span>
        </Link>
      </div>
    </div>
    {/* Chronicle Button should be in the same row */}
    <div className="col-12 col-md-2 col-lg-3 col-sm-1 text-md-end d-flex justify-content-center justify-content-md-end">
      <Link to={`/${server.Chronicle.replace(' ', '')}`}>
        <button
          className={`btn chronicle-button pc-custom2 custom-btn-${server.Chronicle.replace(' ', '').toLowerCase()}`}
        >
          {server.Chronicle}
        </button>
      </Link>
    </div>
  </div>
</div>

  ));

  return (
    <div className="page">
      <div className="page-content row col-12">
      <div className="col-md-3 col-lg-3 leftcol"> {/* 2 columns for the left banner */}
          {hasLeftBanner ? (
            banners2.map((element, index) =>
              element.name === 'Left Banner' && !element.available ? (
                <div key={index} className='pagebanner left-banner col-12'>
                  <a href={`http://${element.website}`} className='testimg' target="_blank" rel="noopener noreferrer">
                  <img 
  className='xaxare img-fluid' 
  crossOrigin='anonymous' 
  src={`${baseURL}${element.image}`} 
  alt="Left Banner" 
  loading="lazy" 
  width='573' 
  style={{ 
    maxWidth: '573px', // Ensures the image doesn't exceed its original width
    width: '100%', // Allows the image to be responsive and fit within its container
    objectFit: 'cover', // This will ensure the image covers the area without stretching
    height:'1100px' 

  }} 
/>
                  </a>
                </div>
              ) : null
            )
          ) : (
            <div className='pagebanner left-banner'>
              <Link className='bannerlink' to={{ pathname: "/BuyBanner" }}>
                <button className='bannerbutton'>
                  Add your ServerBanner by clicking here!
                </button>
              </Link>
            </div>
          )}
        </div>

        <div className="container col-lg-6 col-md-12">
          <h4 className="text-center ">Results</h4>
          {renderServers.length > 0 ? renderServers : <p>No results found.</p>}
        </div>
        <div className="col-md-3 col-lg-3 d-none d-lg-block rightcol gx-0"> {/* 2 columns for the right banner */}
          {hasRightBanner ? (
            banners2.map((element, index) =>
              element.name === 'Right Banner' && !element.available ? (
                <div key={index} className='pagebanner right-banner col-12'>
                  <a href={`http://${element.website}`} className='testimg' target="_blank" rel="noopener noreferrer">
                  <img 
  className='xaxare img-fluid' 
  crossOrigin='anonymous' 
  src={`${baseURL}${element.image}`} 
  alt="Right Banner" 
  loading="lazy" 
  width='573' 
  style={{ 
    maxWidth: '573px', // Ensures the image doesn't exceed its original width
    width: '100%', // Allows the image to be responsive and fit within its container
    objectFit: 'cover', // This will ensure the image covers the area without stretching
    height:'1100px' 

  }} 
/>
                  </a>
                </div>
              ) : null
            )
          ) : (
            <div className='pagebanner right-banner gx-0'>
              <Link className='bannerlink' to={{ pathname: "/BuyBanner" }}>
                <button className='bannerbutton'>
                  Add your ServerBanner by clicking here!
                </button>
              </Link>
            </div>
          )}
        </div>

        <div className="pagination-container">
          <Pagination
            serversPerPage={serversPerPage}
            totalServers={filteredResults.length} // Use filteredResults for pagination
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Search;
