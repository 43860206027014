// validation.js

// Function to validate email format
 const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

// Function to validate password strength
 const validatePassword = (password) => {
    // Example: Password must be at least 8 characters long
    return password.length >= 8;
};
module.exports = {
    validateEmail,
    validatePassword
};