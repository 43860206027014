import React, { useContext, useState } from 'react';
import './CSS/LoginSignUp.css';
import { validateEmail, validatePassword } from '../../validation';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';
import { ServersContext } from '../ServersContext';

const LoginSignup = () => {
    const [agreed, setAgreed] = useState(false);
    const [error, setError] = useState(null);
    const data = useContext(ServersContext);
    const csrfToken = data.csrfToken;
    
    const [state, setState] = useState("Login");
    const [formData, setFormData] = useState({
        username: "",
        password: "",
        email: "",
    });

    const handleCheckboxChange = (e) => {
        setAgreed(e.target.checked);
    };
    
    const changeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const sanitizeInput = () => {
        const sanitizedFormData = {};
        for (const key in formData) {
            sanitizedFormData[key] = DOMPurify.sanitize(formData[key]);
        }
        return sanitizedFormData;
    };

    const handleSubmit = async () => {
        const sanitizedData = sanitizeInput();
        setError(null); // Reset the error state

        if (!validateEmail(sanitizedData.email) || !validatePassword(sanitizedData.password)) {
            setError('Invalid email or password format.');
            return;
        }

        if (state === "Sign Up" && !agreed) {
            setError("You must agree to the terms of use and privacy policy.");
            return;
        }

        if (state === "Sign Up") {
            sanitizedData.agreed = agreed;
        }

        try {
            const url = state === "Login" ? 'https://l2info.eu/api/login' : 'https://l2info.eu/api/signup';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
                body: JSON.stringify(sanitizedData),
                credentials: 'include',
            });

            const responseData = await response.json();
            if (responseData.success) {
                localStorage.setItem('auth-token', responseData.token);
                window.location.replace("/");
            } else {
                setError(responseData.errors || "An error occurred. Please try again.");
            }
        } catch (error) {
            setError('Error during submission. Please try again.');
            console.error('Error:', error);
        }
    };

    return (
        <div className="loginsignup">
            <div className="loginsignup-container">
                <h1>{state}</h1>
                <div className="loginsignup-fields">
                    {state === 'Sign Up' && (
                        <input 
                            name='username' 
                            type="text" 
                            value={formData.username} 
                            onChange={changeHandler} 
                            placeholder='Your Name' 
                        />
                    )}
                    <input 
                        name='email' 
                        value={formData.email} 
                        onChange={changeHandler} 
                        type="email" 
                        placeholder='Email' 
                    />
                    <input 
                        name='password' 
                        value={formData.password} 
                        onChange={changeHandler} 
                        type="password" 
                        placeholder='Password' 
                    />
                    <p>passwords must have at least 1 uppercase,1 lowercase letter,1digit,1 symbol.Minimum 8 characters</p>
                </div>
                
                {error && <p className="error-message">{error}</p>} {/* Displaying the error */}

                <button onClick={handleSubmit}>Continue</button>
                
                {state === 'Sign Up' ? (
                    <p className="loginsignup-login">
                        Already have an account? 
                        <span className='hovertest' onClick={() => setState("Login")}>Login here</span>
                    </p>
                ) : (
                    <p className="loginsignup-login">
                        Create an account? 
                        <span className='hovertest' onClick={() => setState("Sign Up")}>Click here</span>
                    </p>
                )}

                <p className='loginsignup-login'>
                    Forgot your password? 
                    <Link className='clickcolour' style={{ textDecoration: 'none' }} to='/forgot-password'>
                        <span>Click here</span>
                    </Link>
                </p>

                {state === 'Sign Up' && (
                    <div className="loginsignup-agree">
                        <input 
                            className='checkdx' 
                            type="checkbox" 
                            name='terms' 
                            checked={agreed} 
                            onChange={handleCheckboxChange} 
                        />
                        <p>By continuing, I agree to the terms of use and privacy policy</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default LoginSignup;
