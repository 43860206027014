import React, { useContext, useState } from 'react';
import { ServersContext } from '../ServersContext';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const data=useContext(ServersContext);
    const csrfToken =data.csrfToken;
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://l2info.eu/api/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken, // Include the CSRF token here

                },
                body: JSON.stringify({ email }),
                credentials:'include'
            });

            if (!response.ok) {
                throw new Error('Failed to send password reset email');
            }

            setMessage('Password reset email sent successfully');
        } catch (error) {
            console.error('Error:', error);
            setMessage('Failed to send password reset email');
        }
    };

    return (
        <div>
            <h1>Forgot Your Password?</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Email:
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </label>
                <button type="submit">Submit</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ForgotPassword;
