import React, { useContext, useEffect, useState } from 'react';
import './CSS/PageCategory.css';
import banner1 from '../Assets/test2.jpg';
import banner2 from '../Assets2/test.gif';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ServersContext } from '../ServersContext';

export const PageCategory = (props) => {
  const { category, chronicles } = props;
  const [servers, setServers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const serversPerPage = 25;

  useEffect(() => {
    const fetchServers = async () => {
      try {
        const response = await fetch('https://l2info.eu/api/servers'); // Adjust the URL accordingly
        if (response.ok) {
          const data = await response.json();
          const sortedServers = data.servers.sort((a, b) => b.votes - a.votes);
          setServers(sortedServers);
        } else {
          throw new Error('Failed to fetch servers');
        }
      } catch (error) {
        console.error('Error fetching servers:', error);
      }
    };
    fetchServers();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.Chronicle]);

  // Calculate the indices for the current page
  const indexOfLastServer = currentPage * serversPerPage;
  const indexOfFirstServer = indexOfLastServer - serversPerPage;

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filteredServers = props.Chronicle ? servers.filter(server => server.Chronicle === props.Chronicle) : servers;
  const currentServers = filteredServers.slice(indexOfFirstServer, indexOfLastServer);

  const banners = useContext(ServersContext);
  const banners2 = banners.banners;

  const hasLeftBanner = banners2.some(element => element.name === 'Left Banner' && !element.available);
  const hasRightBanner = banners2.some(element => element.name === 'Right Banner' && !element.available);
  const baseURL = `https://l2info.eu/api/`;

  const renderServers = currentServers.map((server, index) => (
    <div className="container mb-3" key={index}>
    <div className="row align-items-center">
      <div className="col-12 col-md-9 col-lg-9 text-center mb-md-0">
        <div className="d-flex flex-column flex-md-row col-12 col-sm-12 align-items-center">
          <Link
            className="btn col-12 col-md-8 col-lg-10 col-sm-12 server-button pc-custom mb-2 d-flex align-items-center"
            to={`/Server/${server.name}`}
          >
            <span className='badge custom-badge'>{indexOfFirstServer + index + 1}</span>
            {server.smallBanner && (
              <img crossorigin="anonymous"
                className="img-fluid rounded-circle me-2 smallbanner"
                src={baseURL + server.smallBanner}
                alt={server.name}
              />
            )}
            <span className="text-md-start text-sm-start w-100 col-sm-12">
              {server.name} {server.XP} - Votes: {server.votes}
            </span>
          </Link>
        </div>
      </div>
      <div className="col-12 col-md-3 col-lg-3 d-flex justify-content-md-end align-items-center justify-content-center">
      <Link to={`/${server.Chronicle.replace(/\s+/g, '').toLowerCase()}`}>
      <button
className={`btn chronicle-button pc-custom2 custom-btn-${server.Chronicle.replace(/\s+/g, '').toLowerCase()}`}
>
            {server.Chronicle}
          </button>
        </Link>
      </div>
    </div>
  </div>
  
  ));

  return (
    <div className="page">
      <div className="page-content col-12 row combinatio">
        {/* Left Banner */}
        <div className="col-md-3 col-lg-3 col-sm-0 leftcol d-md-none d-lg-block"> {/* 2 columns for the left banner */}
          {hasLeftBanner ? (
            banners2.map((element, index) =>
              element.name === 'Left Banner' && !element.available ? (
                <div key={index} className='pagebanner left-banner col-12'>
                  <a href={`http://${element.website}`} className='testimg' target="_blank" rel="noopener noreferrer">
                  <img 
  className='xaxare img-fluid' 
  crossOrigin='anonymous' 
  src={`${baseURL}${element.image}`} 
  alt="Left Banner" 
  loading="lazy" 
  width='573' 
  style={{ 
    maxWidth: '573px', // Ensures the image doesn't exceed its original width
    width: '100%', // Allows the image to be responsive and fit within its container
    objectFit: 'cover', // This will ensure the image covers the area without stretching
    height:'1100px' 

  }} 
/>
                  </a>
                </div>
              ) : null
            )
          ) : (
            <div className='pagebanner left-banner'>
              <Link className='bannerlink' to={{ pathname: "/BuyBanner" }}>
                <button className='bannerbutton'>
                  Add your ServerBanner by clicking here!
                </button>
              </Link>
            </div>
          )}
        </div>

        {/* Main Content */}
        <div className="col-md-11 col-lg-6 mx-auto">
  <h3 className="text-center">{props.Chronicle} {props.category} Servers</h3>
  {renderServers}
  <div className="pagination-container mr-5 pr-5">
    <Pagination
      serversPerPage={serversPerPage}
      totalServers={filteredServers.length}
      paginate={paginate}
      currentPage={currentPage}
    />
  </div>
</div>



        {/* Right Banner */}
        <div className="col-md-3 col-lg-3 d-none d-lg-block rightcol gx-0 d-md-none"> {/* 2 columns for the right banner */}
          {hasRightBanner ? (
            banners2.map((element, index) =>
              element.name === 'Right Banner' && !element.available ? (
                <div key={index} className='pagebanner right-banner col-12'>
                  <a href={`http://${element.website}`} className='testimg' target="_blank" rel="noopener noreferrer">
                  <img 
  className='xaxare img-fluid' 
  crossOrigin='anonymous' 
  src={`${baseURL}${element.image}`} 
  alt="Right Banner" 
  loading="lazy" 
  width='573' 
  style={{ 
    maxWidth: '573px', // Ensures the image doesn't exceed its original width
    width: '100%', // Allows the image to be responsive and fit within its container
    objectFit: 'cover', // This will ensure the image covers the area without stretching
    height:'1100px' 

  }} 
/>
                  </a>
                </div>
              ) : null
            )
          ) : (
            <div className='pagebanner right-banner gx-0'>
              <Link className='bannerlink' to={{ pathname: "/BuyBanner" }}>
                <button className='bannerbutton'>
                  Add your ServerBanner by clicking here!
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageCategory;
