import React, { useState, useEffect, useContext } from 'react';
import UpdateBanners from '../UpdateBanners';
import { ServersContext } from '../ServersContext';
import './CSS/BuyBanner.css'
import { useLocation } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import 'bootstrap/dist/css/bootstrap.min.css';



const BuyBanner = () => {
  const data=useContext(ServersContext);
  const csrfToken =data.csrfToken;
  const [availableSpots, setAvailableSpots] = useState([]);
  const [selectedSpot, setSelectedSpot] = useState(null);
  const [durationDays, setDurationDays] = useState(1); // Default duration
  const [userId, setUserId] = useState(null); // State to store the user ID
  const [userInput, setUserInput] = useState(''); // State to store user input
  const { storeUserId } = useContext(ServersContext);
  const [spotId, setSpotId] = useState(null);
  const location = useLocation();
  const [website, setWebsite] = useState(''); // State to store the website input
  const [availableBanners, setAvailableBanners] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
const [bannerId,setBannerId]=useState(null);
const [image, setImage] = useState(null); // State to store uploaded banner image
const [spotDurationDays, setSpotDurationDays] = useState(1); // State for spot duration days
  const [bannerDurationDays, setBannerDurationDays] = useState(1); // State for banner duration days
 const[bannerAvailable,setBannerAvailable]=useState(false);
 const [requiredCoins, setRequiredCoins] = useState(10); // Default to 10 coins for 1 day
 const [bannerRequiredCoins, setBannerRequiredCoins] = useState(0); // Default to 10 coins for 1 day

  const coinValuePerDay = 10; // Each day costs 10 coins
  const [colorChoice, setColorChoice] = useState('#568259'); // Default color
  const [bannerCoinValuePerDay,setBannerCoinValuePerDay]=useState(25);


  const colors = [
    { name: 'Amber', code: '#ffc107' },
    { name: 'Chinese Violet', code: '#875C74' },
    { name: 'Fern Green', code: '#568259' }
  ];
  // Calculate required coins whenever spotDurationDays changes
  useEffect(() => {
    const calculatedCoins = spotDurationDays * coinValuePerDay;
    setRequiredCoins(calculatedCoins);
  }, [spotDurationDays]);

  
  useEffect(() => {
    if (location.state && location.state.spotId) {
      setSpotId(location.state.spotId);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchAvailableSpots = async () => {
      try {
        const response = await fetch('https://l2info.eu/api/advertising/spots');
        if (!response.ok) {
          throw new Error('Failed to fetch available spots');
        }
        const data = await response.json();
        setAvailableSpots(data);
      } catch (error) {
        console.error('Error fetching available spots:', error);
      }
    };

    fetchAvailableSpots();
  }, []);

  // Function to handle spot selection
  const handleSpotSelect = (spot) => {
    setSelectedSpot({ ...spot, userInput: '', website: '' }); // Store the selected spot along with an empty user input
    setSpotDurationDays(1); // Reset spot duration days when a new spot is selected
  };

  // Function to handle user input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value; // Initialize sanitized value with the original input value
  
    // Validate and sanitize user input based on input name
    if (name === 'userInput') {
      // Example validation: Allow alphanumeric characters only
      sanitizedValue = value.replace(/[^a-zA-Z0-9 ]/g, ''); // Remove non-alphanumeric characters
      setUserInput(sanitizedValue); // Update the userInput state with the sanitized value
    } else if (name === 'website') {
      // Example validation: Ensure valid URL format
      if (isValidURL(value)) {
        sanitizedValue = value; // Keep the original value if it's a valid URL
      } else {
        // Handle invalid URL input (e.g., display error message)
        console.error('Invalid website URL');
      }
      setWebsite(sanitizedValue); // Update the website state with the sanitized value
    }
  
    // Update selected spot with sanitized value
    setSelectedSpot(prevSpot => ({
      ...prevSpot,
      [name]: sanitizedValue // Use the sanitized value based on the input name
    }));
  };
  
  const isValidURL = (url) => {
    // Regular expression pattern to validate URL format
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i;
    // Check if the URL matches the pattern
    return urlPattern.test(url);
  };

  // Function to submit banner purchase
  const handlePurchase = async (e) => {
    e.preventDefault();
    // Check if spot and duration are selected
    if (!selectedSpot) {
      console.error('Please select a spot');
      return;
    }

    try {
      const currentDate= new Date();
      const expirationDate = new Date(currentDate.getTime() + parseInt(spotDurationDays) * 24 * 60 * 60 * 1000);
      // Send request to backend to purchase banner
      const response = await fetch('https://l2info.eu/api/purchase-spot2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in the headers
          

        },      

        body: JSON.stringify({
          userId: storeUserId,
          spotId: selectedSpot.id,
          durationDays: spotDurationDays,
          userInput: selectedSpot.userInput, // Send the user input to the backend
          website: selectedSpot.website, // Send the website input to the backend
          expiresAtDate:expirationDate,
          selectedColor: colorChoice, // Send the selected color
          

        }),            credentials: 'include', // Ensure credentials are sent

      });
      if (!response.ok) {
        throw new Error('Failed to purchase Spot');
      }
      // Handle success (update homepage content)
      console.log('Spot purchased successfully');
    } catch (error) {
      console.error('Error purchasing Spot:', error);
      // Handle error (display error message)
    }
  };

  useEffect(() => {
    const fetchAvailableBanners = async () => {
      try {
        const response = await fetch('https://l2info.eu/api/advertising/banners2');
        if (!response.ok) {
          throw new Error('Failed to fetch available banners');
        }
        const data = await response.json();
        setAvailableBanners(data);
      } catch (error) {
        console.error('Error fetching available banners:', error);
      }
    };

    fetchAvailableBanners();
  }, []);

  // Function to handle banner selection
  const handleBannerSelect = (banner) => {
    setSelectedBanner({ ...banner, website: '', id: banner._id });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
  
    if (!file) {
        console.error('No file selected');
        return;
    }
  
    // Sanitize the file name to prevent directory traversal attacks
    const sanitizedFileName = file.name.replace(/[^\w.-]/g, ''); // Remove special characters
    const sanitizedFile = new File([file], sanitizedFileName, { type: file.type });
  
    // Generate SHA-256 hash of the file
    const hash = await generateSHA256Hash(sanitizedFile);
  
    // Display the selected image (optional)
    displayImage(sanitizedFile);
  
    // Proceed with uploading the sanitized image along with the hash
    setImage({ file: sanitizedFile, hash: hash });
};

const displayImage = (file) => {
    // Create a FileReader object to read the file
    const reader = new FileReader();
  
    // Set up the onload event handler to display the image
    reader.onload = (e) => {
        // Get the data URL representing the image
        const imageDataUrl = e.target.result;
        
        // Display the image (for demonstration purposes)
        const img = document.createElement('img');
        img.src = imageDataUrl;
        document.body.appendChild(img);
    };
  
    // Read the file as a data URL
    reader.readAsDataURL(file);
};

  
  const generateSHA256Hash = async (file) => {
    // Create a FileReader object to read the file
    const reader = new FileReader()};
const sanitizeInput = (input) => {
  // Remove HTML tags using a regular expression
  return input.replace(/<\/?[^>]+(>|$)/g, '');
};
const isValidBannerDuration = (duration) => {
  // Check if duration is a number and within a certain range (e.g., between 1 and 365)
  const parsedDuration = parseInt(duration);
  return !isNaN(parsedDuration) && parsedDuration >= 1 && parsedDuration <= 365;
};


const handleBannerPurchase = async (e) => {
  e.preventDefault();

  // Validate inputs
  if (!selectedBanner || !image || !isValidURL(website) || !isValidBannerDuration(bannerDurationDays)) {
    alert('Please select a valid banner, upload an image, provide a valid website URL, and specify a valid banner duration');
 
    return;
  }

  try {
    // Sanitize inputs
    const sanitizedWebsite = sanitizeInput(website);
 

    // Create FormData object and append sanitized inputs
    const formData = new FormData();
    formData.append('image', image.file);
    formData.append('userId', storeUserId);
     formData.append('bannerId',bannerId);
    formData.append('banner_Id', selectedBanner);
    formData.append('durationDays', bannerDurationDays);
    formData.append('website', sanitizedWebsite);
    

    // Send request to purchase banner
    const response = await fetch('https://l2info.eu/api/purchase-banner2', {
      method: 'POST',          'Content-Type': 'application/json',

      body: formData,
      mode: 'cors', // Add mode: 'cors' here
      headers: {
        'CSRF-Token': csrfToken, // Include the CSRF token here
    },
      credentials: 'include', // Ensure credentials are sent
    });

const data = await  response.json();

const data2 = await   data.data;

    if (response.status === 400) {
      alert('Banner already purchased');
      return;
    }

    if (response.status === 401) {
      // Show error message from backend
      alert(`Failed to purchase banner: ${data.errors ? data.errors[0].msg : 'Unknown error'}`);
      return;
    }

    alert('Banner purchased successfully');

  // Fetch updated banners after purchase
const bannersResponse = await fetch('https://l2info.eu/api/advertising/banners', {
  
  mode: 'cors', // Add mode: 'cors' here as well
});
if (!bannersResponse.ok) {
  throw new Error('Failed to fetch available banners');
}
const updatedBanners = await bannersResponse.json();
setAvailableBanners(updatedBanners);
  } catch (error) {
    console.error('Error purchasing Banner:', error);
  }
};





  

  const handleBannerChange = (e) => {
    const { name, value } = e.target;
    if (name === 'userInput') {
      setUserInput(value); // Update the userInput state with the latest value
    } else if (name === 'website') {
      setWebsite(value); // Update the website state with the latest value
    }
    else if (name === 'bannerId') {
      setBannerId(value); // Update the website state with the latest value
    }
    setSelectedBanner(prevBanner => ({
      ...prevBanner,
      [name]: value // Use the latest value of userInput or website based on the input name
    }));
  };
useEffect(() => {
  const valuePerDay =  (bannerId == 1 || bannerId == 2) ? 25 : 12;
 
      const calculatedValue2 = bannerDurationDays * valuePerDay;
  setBannerRequiredCoins(calculatedValue2);
}, [bannerDurationDays,bannerId]);
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'short',
    timeStyle: 'medium'
  }).format(date);
};

return (
  <div className="BuyBanner container py-5">
    <h1>Available Advertising Spots</h1>
    <ul className="list-group">
      {availableSpots.map((spot) => (
        <li key={spot.id} className="list-group-item d-flex justify-content-between align-items-center">
          <span>
            {spot.name} - {spot.available ? 'Available' : `Not Available, available at: ${formatDate(spot.expiresAtDate)}`}
          </span>
          <button className="btn custom-btn-color" onClick={() => handleSpotSelect(spot)}>Select Spot</button>
        </li>
      ))}
    </ul>
    {selectedSpot && (
      <form onSubmit={handlePurchase} className="mt-4">
        <div className="mb-3">
          <h4>Required Coins: {requiredCoins}</h4>
          <label className="form-label">
            Duration (Days):
            <input type="number" className="form-control" value={spotDurationDays} onChange={(e) => setSpotDurationDays(e.target.value)} />
          </label>
        </div>
        <div className="mb-3">
          <label className="form-label">Select Color:</label>
          <div className="d-flex flex-wrap gap-2">
            {colors.map((color) => (
              <div key={color.code} className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id={color.code}
                  name="color"
                  value={color.code}
                  checked={colorChoice === color.code}
                  onChange={(e) => setColorChoice(e.target.value)}
                  style={{ display: 'none' }}
                />
                <label htmlFor={color.code} className="form-check-label" style={{
                  display: 'block',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  backgroundColor: color.code,
                  border: colorChoice === color.code ? '2px solid #000' : '1px solid #ddd',
                  cursor: 'pointer'
                }}></label>
              </div>
            ))}
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label">
            Server Description, which will be displayed
            <input type="text" className="form-control" name="userInput" value={selectedSpot.userInput} onChange={handleInputChange} />
          </label>
        </div>
        <div className="mb-3">
          <label className="form-label">
            Website:
            <input type="text" className="form-control" name="website" value={selectedSpot.website} onChange={handleInputChange} placeholder="example: www.google.com" />
          </label>
        </div>
       
        {selectedSpot.available ? (
          <button className="btn custom-btn-color" type="submit">Purchase Spot</button>
        ) : (
          <p className="text-danger">This spot is not available.</p>
        )}
      </form>
    )}

    <h1 className="mt-5">Available Banners</h1>
    {availableBanners && (
      <form className='testazz'onSubmit={handleBannerPurchase}>
        <div className="mb-3">
          <label className="form-label">
            Select Banner:
            <select className="form-select" value={selectedBanner ? selectedBanner : ''} onChange={(e) => { setSelectedBanner(e.target.value); setBannerId(e.target.options[e.target.selectedIndex].getAttribute('data-key')); }}>
              <option value="">Select a Banner</option>
              {availableBanners.map((banner) => (
                <option key={banner.id} value={banner._id} data-key={banner.id}>
                  {banner.name}{banner.id === 1 || banner.id === 2 ? ` price: ${banner.price} per day` : ` price: ${banner.price} per day`} - {banner.available ? 'Available' : `Not Available, available at: ${formatDate(banner.expiresAtDate)}`}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="mb-3">
          <h4>Required Coins: {bannerRequiredCoins}</h4>
          <label className="form-label">
            Duration (Days):
            <input type="number" className="form-control" value={bannerDurationDays} onChange={(e) => setBannerDurationDays(e.target.value)} />
          </label>
        </div>
        <div className="mb-3">
          <label className="form-label">
            Upload Image:
            <input type="file" className="form-control" accept="image/*" onChange={(e) => handleImageUpload(e)} />
          </label>
        </div>
        <div className="mb-3">
          <label className="form-label">
            Website (example: https://www.google.com)
            <input type="text" className="form-control" name="website" value={website} onChange={(e) => setWebsite(e.target.value)} />
          </label>
        </div>
        
        <button className="btn custom-btn-color" type="submit">Purchase Banner</button>
        </form>
    )}
    <div><p className='p-tag'>note:spots are the places in the premium/new premium servers section. 
      Home bottom banner 1-4 are going from up to bottom ,accordingly</p></div>
  </div>
);
};

export default BuyBanner;
