import React, { useEffect, useState } from 'react';
import banner1 from '../Assets/test2.jpg';
import banner2 from '../Assets2/test.gif';
import './ServerDisplay.css';
import { Link, useParams } from 'react-router-dom';
import { ServersContext } from '../ServersContext';
import { useContext } from 'react';
export const ServerDisplay = ({ }) => {
  const { name } = useParams();
  const [server, setServer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [voted, setVoted] = useState(false); // State to track whether vote has been cast
  const [foundServer, setFoundServer] = useState(null); // State to hold the found server
  const { storeUserId } = useContext(ServersContext);
const data=useContext(ServersContext);
const csrfToken =data.csrfToken;
  useEffect(() => {
    const fetchServerInfo = async () => {
      try {
        const response = await fetch(`https://l2info.eu/api/servers`);
        if (response.ok) {
          const data = await response.json();
           
          const serverData = data.servers.find(server => server.name === name);
          if (serverData) {
            setServer(serverData);
            setFoundServer(serverData); // Set foundServer
          } else {
            throw new Error('Server not found');
          }
        } else {
          throw new Error('Failed to fetch server information');
        }
      } catch (error) {
        console.error('Error fetching server information:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchServerInfo();
  }, [name]);
  useEffect(() => {
    if (foundServer && foundServer._id) {
        // Other code related to handling the vote
    } else {
        console.error('Error: foundServer or foundServer._id is null');
    }

}, []);

const handleVote = async () => {
  try {
      // Check if foundServer and foundServer._id are both defined
      if (!foundServer || !foundServer._id) {
          console.error('Error: foundServer or foundServer._id is null or undefined');
          return;
      }
      
      console.log('Attempting to vote...');
      
      const response = await fetch(`https://l2info.eu/api/update-votes`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'CSRF-Token': csrfToken, // Include the CSRF token here

          },
          body: JSON.stringify({ serverId: foundServer._id, userId: storeUserId }),
          credentials:'include'
      });
      
      if (response.ok) {
          const data = await response.json();
          
           localStorage.setItem('voted', true); // Store voted state in localStorage
          setVoted(true); // Update voted state to true
          
          // Optionally store voteToken if needed
       } else if (response.status === 403) {
          const responseData = await response.json();
          const { error, timeRemaining } = responseData;
          console.log('Vote unsuccessful:', error, 'time remaining', timeRemaining);

          // Check if timeRemaining is defined
          if (timeRemaining !== undefined) {
              // Convert timeRemaining (seconds) to hours, minutes, and seconds
              const hours = Math.floor(timeRemaining / 3600);
              const minutes = Math.floor((timeRemaining % 3600) / 60);
              const seconds = timeRemaining % 60;

              // Pad numbers with leading zeros if necessary
              const hoursStr = hours.toString().padStart(2, '0');
              const minutesStr = minutes.toString().padStart(2, '0');
              const secondsStr = seconds.toString().padStart(2, '0');

              // Format the remaining time as HH:MM:SS
              const formattedTimeRemaining = `${hoursStr}:${minutesStr}:${secondsStr}`;

              // Display time remaining to user
              document.getElementById('time').textContent = `  Time remaining: ${formattedTimeRemaining}`;
          } else {
              // Handle case where timeRemaining is not provided
              document.getElementById('time').textContent = `You have already voted within the last 12 hours. Please try again later.`;
          }
      } else {
          console.error('Failed to update votes');
          throw new Error('Failed to update votes');
      }
  } catch (error) {
      console.error('Error updating votes:', error);
  }
};



const banners = useContext(ServersContext);
  const banners2 = banners.banners;

  const hasLeftBanner = banners2.some(element => element.name === 'Left Banner' && !element.available);
  const hasRightBanner = banners2.some(element => element.name === 'Right Banner' && !element.available);
  const baseURL = `https://l2info.eu/api/`;


  const dateString = server?.OpeningDate; // Using optional chaining to avoid errors if Server is null or undefined
  if (!dateString) {
    // Handle case where OpeningDate is not available
    return <p>Opening date not available</p>;
  }
  
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const HumanDate = `${year}-${month}-${day} ${hours}:${minutes}`;
  
  
  return (
    <div className="page-content col-12 row combinatio">
  
  {/* Left Banner */}
  <div className="col-md-3 col-lg-3 leftcol"> {/* 2 columns for the left banner */}
    {hasLeftBanner ? (
      banners2.map((element, index) =>
        element.name === 'Left Banner' && !element.available ? (
          <div key={index} className='pagebanner left-banner col-12'>
            <a href={`http://${element.website}`} className='testimg' target="_blank" rel="noopener noreferrer">
              <img className='xaxare img-fluid' crossorigin="anonymous" src={`${baseURL}${element.image}`} alt="Left Banner" loading="lazy" />
            </a>
          </div>
        ) : null
      )
    ) : (
      <div className='pagebanner left-banner'>
        <Link className='bannerlink' to={{ pathname: "/BuyBanner" }}>
          <button className='bannerbutton'>
            Add your ServerBanner by clicking here!
          </button>
        </Link>
      </div>
    )}
  </div>
  
  {/* Left Box */}
  <div className="col-12 col-md-6 mb-3"> {/* Center content, adjust the grid */}
    <div className="row">
      <div className="col-12 col-md-6 mt-5">
        <div className="serverdisplay-leftbox p-3 border rounded">
          <p className='heading'>General Information:</p>
          {loading ? (
            <p>Loading server information...</p>
          ) : server ? (
            <>
              <p className='infop'>Name: {server.name}</p>
              <p className='infop'>Grand Opening: {HumanDate}</p>
              <p className='infop'>Website: {server.website}</p>
              <p className='infop'>Chronicle: {server.Chronicle}</p>
              <p className='infop'>XP Rate: {server.XPrate}</p>
              {server.Premium && <p className='infop'>Description: {server.description}</p>}
              {server.rvr && <p className='infop'>RVR</p>}
              {server.multiskill && <p className='infop'>Multiskill</p>}
              {server.gve && <p className='infop'>GVE</p>}
            </>
          ) : (
            <p>Server information not found</p>
          )}
        </div>
      </div>

      {/* Right Box */}
      <div className="col-12 col-md-6 mt-5">
        <div className="serverdisplay-rightbox p-3 border rounded">
          {!voted && <p id='time'> </p>}
          {!voted && <button className="btn btn-primary" onClick={handleVote}>Vote!</button>}
          <a
            className='btn btn-secondary'
            href={server.website?.startsWith('http') ? server.website : `https://${server.website}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to {server.name}
          </a>
        </div>
      </div>
    </div>
  </div>
  
  {/* Right Banner */}
  <div className="col-md-3 col-lg-3 d-none d-lg-block rightcol gx-0"> {/* 2 columns for the right banner */}
    {hasRightBanner ? (
      banners2.map((element, index) =>
        element.name === 'Right Banner' && !element.available ? (
          <div key={index} className='pagebanner right-banner col-12 gx-0'>
            <a href={`http://${element.website}`} className='testimg' target="_blank" rel="noopener noreferrer">
              <img className='xaxare img-fluid' crossorigin="anonymous"  src={`${baseURL}${element.image}`} alt="Right Banner" loading="lazy" />
            </a>
          </div>
        ) : null
      )
    ) : (
      <div className='pagebanner right-banner gx-0'>
        <Link className='bannerlink' to={{ pathname: "/BuyBanner" }}>
          <button className='bannerbutton'>
            Add your ServerBanner by clicking here!
          </button>
        </Link>
      </div>
    )}
  </div>

</div>

  );
};

  
