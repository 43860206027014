import React from 'react';
import './CSS/Pagination.css';

const Pagination = ({ serversPerPage, totalServers, paginate, currentPage }) => {
  const totalPages = Math.ceil(totalServers / serversPerPage);
  const pagesToShow = 15;

  // Determine the range of pages to display
  let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

  // Adjust startPage if endPage is the last page and there aren't enough pages to show
  if (endPage - startPage < pagesToShow - 1) {
    startPage = Math.max(1, endPage - pagesToShow + 1);
  }

  const handlePageChange = (pageNumber) => {
    paginate(pageNumber);
    window.scrollTo({
      top: 0, // Scroll to the top of the window
      behavior: 'smooth' // Optional: define the scrolling behavior as smooth
    });
  };

  return (
    <nav className="pagination-container">
      {/* Previous Button */}
      <button
        className="page-link"
        onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}
        disabled={currentPage === 1}
      >
        <i className="fas fa-arrow-left"></i>
      </button>

      <div className="pagination-items">
        {/* Show dots and first page */}
        {startPage > 1 && totalPages > pagesToShow && (
          <>
            <button
              onClick={() => handlePageChange(1)}
              className={`page-item ${currentPage === 1 ? 'active' : ''}`}
            >
              1
            </button>
            {startPage > 2 && <span className="page-dots">...</span>}
          </>
        )}

        {/* Page Numbers */}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(number => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`page-item ${currentPage === number ? 'active' : ''}`}
          >
            {number}
          </button>
        ))}

        {/* Show dots and last page */}
        {endPage < totalPages && totalPages > pagesToShow && (
          <>
            {endPage < totalPages - 1 && <span className="page-dots">...</span>}
            <button
              onClick={() => handlePageChange(totalPages)}
              className={`page-item ${currentPage === totalPages ? 'active' : ''}`}
            >
              {totalPages}
            </button>
          </>
        )}
      </div>

      {/* Next Button */}
      <button
        className="page-link"
        onClick={() => handlePageChange(currentPage < totalPages ? currentPage + 1 : totalPages)}
        disabled={currentPage === totalPages}
      >
        <i className="fas fa-arrow-right"></i>
      </button>
    </nav>
  );
};

export default Pagination;
