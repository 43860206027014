import React from "react";
import '../Pages/CSS/PageCategory.css'

function Button ({className, Chronicle}) {
    const getChronicleClass = () => {
        switch (Chronicle) {
            case 'Interlude':
                return 'interlude';
            case 'H5':
                return 'h5';
            case 'Essence':
                return 'essence';
            default:
                return ''; // Default class if category is not matched
        }
    };

    return (
        <button className={`${className} ${getChronicleClass()}`}>
            {Chronicle}
        </button>
    );
}

export default Button;
