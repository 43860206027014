import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = () => {
    // Implement your authentication check logic here
    // For example, check if a token exists in localStorage
    return !!localStorage.getItem('auth-token');
  };

  return isAuthenticated() ? <Component {...rest} /> : <Navigate to="/loginSignUp" />;
};

export default PrivateRoute;
