import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ServersContext } from '../ServersContext';

const ResetPassword = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const data=useContext(ServersContext);
    const csrfToken =data.csrfToken;
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://l2info.eu/api/reset-password/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken, // Include the CSRF token here

                },
                body: JSON.stringify({ password }),
                credentials:'include'

            });
console.log('token',token)
            if (!response.ok) {
                throw new Error('Failed to reset password');
            }

            setMessage('Password reset successfully');
        } catch (error) {
            console.error('Error:', error);
            setMessage('Failed to reset password');
        }
    };

    return (
        <div>
            <h1>Reset Your Password</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    New Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </label>
                <button type="submit">Submit</button>
                <p>Passwords must have at least 1 uppercase letter,1 symbol,1 number and 8 characters</p>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ResetPassword;
