import React, { useContext, useEffect, useState } from 'react';
import './CSS/AddMyServer.css';
import { ServersContext } from '../ServersContext';

const AddMyServer = () => {
    const [servers, setServers] = useState([]); // State to hold all servers
    const [serverName, setServerName] = useState('');
    const [PremiumChecked, setPremiumChecked] = useState(false);
    const [chronicle, setChronicle] = useState('');
    const [XPrate,setXPrate] = useState('');
    const [openingDate, setOpeningDate] = useState('');
    const [description, setDescription] = useState('');
    const [rvrChecked, setRvrChecked] = useState(false);
    const [gveChecked, setGveChecked] = useState(false);
    const [multiskillChecked, setMultiskillChecked] = useState(false);
    const [error, setError] = useState('');
    const [otherChronicle, setOtherChronicle] = useState('');
    const [userToken, setUserToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [banner80x80, setBanner80x80] = useState(null); // State to hold uploaded image
    const [website, setWebsite] = useState('');

    const [showRelogMessage, setShowRelogMessage] = useState(false); // State to control displaying re-login message
    const [servUser, setServUser] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [walletBalance, setWalletBalance] = useState(0); // New state for wallet balance
   
 // Function to handle file upload
 const handleFileChange = (event) => {
    const file = event.target.files[0];
    setBanner80x80(file);
};

    useEffect(() => {
        // Fetch the user token from wherever it's stored (e.g., localStorage, sessionStorage)
        const token = localStorage.getItem('auth-token');
        if (token) {
            setUserToken(token);
        }
    }, []);
    const data=useContext(ServersContext);
    const csrfToken =data.csrfToken;
    useEffect(() => {
        const fetchUserCoins = async () => {
            try {
                
                const response = await fetch('https://l2info.eu/api/user-coins', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userToken}`,
                        'CSRF-Token': csrfToken, // Include the CSRF token here

                    },
                });
        
        
                if (response.status === 304) {
                    // Resource not modified, keep the existing userCoins state
                    setLoading(false);
                    return;
                }
        
                if (!response.ok) {
                    throw new Error('Unauthorized: Please re-login'); // Throw specific error for re-login
                }
        
                const data = await response.json();
                setWalletBalance(data.coins);

                // Ensure data.user is not null or undefined before accessing _id
                if (data.user && data.user._id) {
                    // Access _id from data.user
                    // Now you can set the state
                    setServUser(data.user);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user coins:', error);
                if (error.message === 'Unauthorized: Please re-login') {
                    setShowRelogMessage(true); // Set state to show re-login message
                } else {
                    setError('Error fetching user coins'); // Set error state
                }
                setLoading(false);
            }
        };
        

        fetchUserCoins();
    }, [userToken]);


    // Function to fetch all servers from the backend
    const fetchServers = async (csrfToken) => {
        try {
            const response = await fetch('https://l2info.eu/api/servers', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken, // Include the CSRF token here
                },
             });
            
            if (response.ok) {
                const data = await response.json();
                setServers(data.servers); // Update the servers state with fetched data
            } else {
                throw new Error('Failed to fetch servers');
            }
        } catch (error) {
            console.error('Error fetching servers:', error);
            setError('Failed to fetch servers');
        }
    };

    // Fetch all servers when the component mounts
    useEffect(() => {
        fetchServers();
    }, []);

    const handleOptionChange = (event) => {
        const value = event.target.value;
    
        if (value !== 'other') {
            setChronicle(value);
        } else {
            // Handle the 'other' case separately
            // Clear otherChronicle if user switches back to 'other' from other options
            setChronicle(value);
            setOtherChronicle(''); // Clear otherChronicle state
        }
    };
    
    // Inside your AddMyServer component

// Function to fetch the token from local storage
const getTokenFromLocalStorage = () => {
    const token = localStorage.getItem('auth-token');
    return token;
};

// Get the token from local storage
const token = getTokenFromLocalStorage();

const handlePremiumChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
        if (walletBalance >= 13) { // Assume the cost is 13 coins
             
            setPremiumChecked(true);
            setError('');
        } else {
            setError('Insufficient balance for Premium.');
        }
    } else {
         
        setPremiumChecked(false);
        // Reset premium details when unchecked
        setDescription('');
        setBanner80x80(null);
    }
};


// Now you can use the 'token' variable in your fetch request


    const handleSubmit = async (event) => {
        event.preventDefault();
        
        // Check if a server with the same name already exists
        const existingServer = servers.find(server => server.name === serverName);
        if (existingServer) {
            setError('Server with the same name already exists.');
            return;
        }
        const serverData = {
            name: serverName,
            Premium:PremiumChecked,
            Chronicle: chronicle === 'other' ? otherChronicle : chronicle,
            XPrate,
            openingDate,
            description,
            rvr: rvrChecked,
            gve: gveChecked,
            multiskill: multiskillChecked,
            smallBanner:banner80x80,
            website
        };
        
        const formData = new FormData();
    formData.append('name', serverName);
    formData.append('Premium', PremiumChecked);
    formData.append('Chronicle', chronicle === 'other' ? otherChronicle : chronicle);
    formData.append('XPrate', XPrate);
    formData.append('openingDate', openingDate);
    formData.append('description', description);
    formData.append('rvr', rvrChecked);
    formData.append('gve', gveChecked);
    formData.append('multiskill', multiskillChecked);
    formData.append('image', banner80x80); // Make sure 'image' matches the field name expected on the backend
    formData.append('website', website); // Make sure 'image' matches the field name expected on the backend

    try {
        const response = await fetch('https://l2info.eu/api/add-server', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'CSRF-Token': csrfToken, // Include the CSRF token here

            },
            body: formData,
                         credentials: 'include', // Ensure credentials are sent
        });

        if (response.ok) {
            console.log('Server details updated successfully');
            setServerName('');
            setPremiumChecked(false);
            setChronicle('');
            setOtherChronicle('');
            setXPrate('');
            setOpeningDate('');
            setDescription('');
            setRvrChecked(false);
            setGveChecked(false);
            setMultiskillChecked(false);
            setError('');
            setBanner80x80(null);
            fetchServers();
            setWebsite('');
        } else {
            throw new Error('Failed to update server details');
        }
    } catch (error) {
        console.error('Error updating server details:', error);
        setError('Failed to update server details. Please try again.');
    }
};
    return (
        <div className='addpage'>
            <form className='formtag' onSubmit={handleSubmit}>
                <div className='labeltag'>
                    <label htmlFor="serverName">Server Name:</label>
                    <input
                        type="text"
                        id="serverName"
                        value={serverName}
                        onChange={(event) => setServerName(event.target.value)}
                        className='inputtag'
                    />
                </div>

                <div className='labeltag'>
                    <label htmlFor="serverChronicle">Select Chronicle:</label>
                    <select
                        id="Chronicle"
                        value={chronicle}
                        onChange={handleOptionChange}
                        className='selecttag'
                    >
                        <option value="">Select...</option>
                        <option value="Interlude">Interlude</option>
                        <option value="H5">H5</option>
                        <option value="Classic">Classic</option>
                        <option value="Essence">Essence</option>
                        <option value="Hellbound">Hellbound</option>
                        <option value="Gracia">Gracia</option>
                        <option value="Gracia Final">Gracia Final</option>
                        <option value="Gracia Epilogue">Gracia Epilogue</option>
                        <option value="Freya">Freya</option>
                        <option value="High Five">High Five</option>
                        <option value="GoD">GoD</option>
                        <option value="Grand Crusade">Grand Crusade</option>
                        <option value="Helios">Helios</option>
                        <option value="Fafurion">Fafurion</option>
                        <option value="Prelude of War">Prelude of War</option>
                        <option value="Homunculus">Homunculus</option>
                        <option value="Olympiad">Olympiad</option>
                        <option value="Prelude">Prelude</option>
                        <option value="The Kamael">The Kamael</option>
                        <option value="Goddess of Destruction">Goddess of Destruction</option>
                        <option value="Awakening">Awakening</option>
                        <option value="Harmony">Harmony</option>
                        <option value="Tauti">Tauti</option>
                        <option value="Glory Days">Glory Days</option>
                        <option value="Lindvior">Lindvior</option>
                        <option value="Valiance">Valiance</option>
                        <option value="Ertheia">Ertheia</option>
                        <option value="Infinite Odyssey">Infinite Odyssey</option>
                        <option value="Underground">Underground</option>
                        <option value="Salvation">Salvation</option>
                    </select>
                </div>

                {chronicle === 'other' && (
                    <div className='labeltag'>
                        <label htmlFor="OtherChronicle">Enter Chronicle:</label>
                        <input
                            type="text"
                            id="OtherChronicle"
                            value={otherChronicle}
                            onChange={(event) => setOtherChronicle(event.target.value)}
                            className='inputtag'
                        />
                    </div>
                )}

                <div className='labeltag'>
                    <label htmlFor="XPrate">XP Rate:</label>
                    <input
                        type="text"
                        id="XPrate"
                        value={XPrate}
                        onChange={(event) => setXPrate(event.target.value)}
                        className='inputtag'
                    />
                </div>
                <div className='labeltag'>
                    <label htmlFor="website">Website</label>
                    <input
                        type="text"
                        id="website"
                        value={website}
                        onChange={(event) => setWebsite(event.target.value)}
                        className='inputtag'
                    />
                </div>
                <div className='labeltag'>
                    <label htmlFor="openingDate">Opening Date:</label>
                    <input
                        type="datetime-local"
                        id="openingDate"
                        value={openingDate}
                        onChange={(event) => setOpeningDate(event.target.value)}
                        className='inputtag'
                    />
                </div>

                {PremiumChecked && (
                    <div className='labeltag'>
                        <label htmlFor="description">Description:</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(event) => setDescription(event.target.value)}
                            className='textarea'
                        ></textarea>
                    </div>
                )}

                <div className='labeltag'>
                    <input
                        type="checkbox"
                        name="rvr"
                        checked={rvrChecked}
                        onChange={(event) => setRvrChecked(event.target.checked)}
                    />
                    RVR
                </div>

                <div className='labeltag'>
                    <input
                        type="checkbox"
                        name="gve"
                        checked={gveChecked}
                        onChange={(event) => setGveChecked(event.target.checked)}
                    />
                    GVE
                </div>

                <div className='labeltag'>
                    <input
                        type="checkbox"
                        name="multiskill"
                        checked={multiskillChecked}
                        onChange={(event) => setMultiskillChecked(event.target.checked)}
                    />
                    Multiskill
                </div>

                <div className='labeltag'>
                    <input
                        type="checkbox"
                        name="Premium"
                        checked={PremiumChecked}
                        onChange={handlePremiumChange}
                    />
                    Premium
                </div>

                {PremiumChecked && (
                    <div className='labeltag'>
                        <label htmlFor="serverImage">Server Image (80x80):</label>
                        <input
                            type="file"
                            id="serverImage"
                            accept="image/*"
                            onChange={handleFileChange}
                            className='inputtag'
                        />
                    </div>
                )}

                <button type="submit">Add Server</button>
            </form>
            <p>If you select premium, you will have various bonuses</p>
        </div>
    );
};


export default AddMyServer;
