import React from 'react';
import './Pages/CSS/SearchArea.css';

const SearchArea = ({ searchQuery, setSearchQuery, handleSearch }) => {
  const handleClick = () => {
    handleSearch();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="col-4 col-md-4 col-lg-2 mb-3">
  <div className="d-flex flex-column flex-md-row">
    <input
      type="text"
      className="form-control col-md-12"  // Adjust the width to take the full space
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleClick();  // Trigger search action
        }
      }}
      placeholder="Search..."
    />
  </div>
</div>

  );
};

export default SearchArea;
